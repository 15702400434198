import { useMemo, useState } from 'react';
import { Widget } from '@typeform/embed-react';

import { getAgeGroup, AgeGroup } from '@repo/utils';
import useSubmitFinishedPartnerSurvey from 'services/surveys/useSubmitFinishedPartnerSurvey';
import { EventSurveyCompletedWithKit } from 'views/TypeformSurvey/TypeformSurvey';

import { Typography } from 'components';
import { Button, ButtonColors, Spinfinity } from '@repo/ui';
import { PartnerKitStepProps } from '../KitActivationForPartners';
import { PartnerLogo } from '../PartnerLogo';

import styles from './SurveyStep.module.scss';

export const SurveyStep = ({
  setActivationState,
  activationState,
  step,
  setCurrentActivationStep,
}: PartnerKitStepProps) => {
  const [view, setView] = useState<'intro' | 'typeform'>('intro');
  const [submittingToTypeform, setSubmittingToTypeform] = useState(false);
  const submitSurveyMutation = useSubmitFinishedPartnerSurvey();
  const { kit, tinyAccount } = activationState;

  //determine Typeform survey id based on kit sampling type and age
  const typeformId = useMemo(() => {
    if (!kit?.sampling_type) return null;
    if (kit.sampling_type === 'vaginal') {
      return kit.partner?.typeform_vaginal_survey_id;
    }
    if (
      kit.sampling_type === 'stool' &&
      getAgeGroup(kit.age_at_sampling || 0) === AgeGroup.ADULT
    ) {
      return kit.partner?.typeform_adult_survey_id;
    }
    return kit.partner?.typeform_toddler_survey_id;
  }, [kit?.sampling_type, kit?.age_at_sampling, kit?.partner]);

  const formProperties = useMemo(() => {
    if (!tinyAccount?.id || !tinyAccount?.sex || !kit?.id) {
      return null;
    }
    return {
      kit_id: kit.id,
      tinyaccount_id: tinyAccount.id,
      sex: tinyAccount.sex,
    };
  }, [tinyAccount?.id, kit?.id, tinyAccount?.sex]);

  const onSubmitTypeform = async () => {
    if (!typeformId || !kit?.id || !tinyAccount?.id) return;
    const eventProperties: EventSurveyCompletedWithKit = {
      kit_id: kit.id,
      form_id: typeformId,
      tinyaccount_id: tinyAccount.id,
    };
    setSubmittingToTypeform(true);
    await submitSurveyMutation.mutateAsync(eventProperties);
    setSubmittingToTypeform(false);
    setCurrentActivationStep(step + 1);
  };

  // Loading state while we wait for required data
  if (!formProperties || !typeformId) {
    return (
      <div className='flex flex-column items-center justify-center h-full'>
        <Spinfinity />
        <Typography variant='body-s' className='mt-2 grey-text'>
          Loading survey...
        </Typography>
      </div>
    );
  }
  // Temporal state while we send the data to Typeform
  if (submittingToTypeform) {
    return (
      <div className='flex flex-column items-center justify-center h-full'>
        <Spinfinity />
        <Typography variant='body-s' className='mt-2 grey-text'>
          Submitting survey...
        </Typography>
      </div>
    );
  }
  return view === 'intro' ? (
    <div className='flex flex-column gap-4 pt-4'>
      <PartnerLogo url={kit?.partner?.logo} />
      <div className='mt-3'>
        <Typography variant='body-m'>{`Kit ID: ${kit.id}`}</Typography>
      </div>
      <Typography variant='heading-l'>
        We have a few questions for you about your sample, diet, and lifestyle.
      </Typography>
      <Button
        label='Tell us about you'
        width='full'
        color={ButtonColors.PURPLE}
        className='mt-3'
        onClick={() => setView('typeform')}
      />
    </div>
  ) : (
    <div className={styles.widgetContainer}>
      <Widget
        id={typeformId}
        style={{ width: '100%', height: '100%' }}
        hidden={formProperties}
        onSubmit={onSubmitTypeform}
      />
    </div>
  );
};
