import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import c from 'classnames';

import { KIT_PRICE } from 'assets';
import useSubscription from 'services/general/useSubscription';
import useUpdateSubscription from 'services/account/useUpdateSubscription';

import { Button, LinkButton } from 'components';
import { Icon, Typography } from '@repo/ui';
import { StatusPill } from '../SubscriptionField/SubscriptionField';

import styles from './SubscriptionDetail.module.scss';
import { toast } from 'react-toastify';
import { BackButton } from 'components/BackButton/BackButton';

type SubscriptionFormValues = {
  date_next_charge_scheduled: string;
  quantity: number;
};
//TODO: clean up with useFeature('subscriptions_v2')
const SubscriptionDetail = () => {
  const navigate = useNavigate();
  const subscriptionMutation = useUpdateSubscription();
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const { data } = useSubscription(subscriptionId);

  const [quantity, setQuantity] = useState<number>(1);
  const [payment, setPayment] = useState<number>(KIT_PRICE);
  const [address, setAddress] = useState<string>('');
  useEffect(() => {
    //kit quantity
    if (data?.subscription?.quantity) {
      setQuantity(data.subscription.quantity);
      setPayment(data.subscription.quantity * KIT_PRICE);
    }
    //user address
    if (data?.customer?.include?.addresses?.length) {
      let tempAddress = data.customer.include.addresses[0]?.address1 ?? '';
      if (data.customer.include.addresses[0]?.city) {
        tempAddress =
          tempAddress + ' ' + data.customer.include.addresses[0]?.city;
      }
      if (data.customer.include.addresses[0]?.province) {
        tempAddress =
          tempAddress + ', ' + data.customer.include.addresses[0]?.province;
      }
      if (data.customer.include.addresses[0]?.zip) {
        tempAddress =
          tempAddress + ' ' + data.customer.include.addresses[0]?.zip;
      }
      setAddress(tempAddress);
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SubscriptionFormValues>({ mode: 'onTouched' });
  const modifyQuantity = (newQuantity: number) => {
    if (newQuantity >= 1) {
      setQuantity(newQuantity);
      setPayment(newQuantity * KIT_PRICE);
    }
  };
  const submitSubscription = async (formData: SubscriptionFormValues) => {
    if (!subscriptionId) return;
    const submitData = {
      next_charge_date: formData.date_next_charge_scheduled,
      quantity,
    };
    try {
      await subscriptionMutation.mutateAsync({
        body: submitData,
        subscriptionId: parseInt(subscriptionId),
      });
      navigate(`/account`);
      toast.success('Subscription updated');
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
  };

  if (!data?.subscription) return <></>;
  return (
    <div className={styles.accountContainer}>
      <div>
        <NavLink to='/account'>
          <BackButton variant='link' />
        </NavLink>
      </div>
      <Typography variant='heading-xl' className={styles.title}>
        {data.subscription.shopify_product_name || 'Kit subscription'}
      </Typography>
      <div className='mb-3'>
        <StatusPill status={data.subscription.status} />
      </div>
      <div className={c(styles.iconRow)}>
        <Icon name='repeatFilled' size='xs' />
        <span>{`Every ${data.subscription.order_interval_frequency} ${
          data.subscription.order_interval_unit
        }${data.subscription.order_interval_frequency > 1 ? 's' : ''}`}</span>
      </div>
      <form onSubmit={handleSubmit(submitSubscription)}>
        <div className={styles.borderedSection}>
          <div className={styles.betweenRow}>
            <span className={styles.subtitle}>Kits per delivery</span>
            <div>
              {data.subscription.status === 'active' && (
                <span
                  className={c(
                    styles.action,
                    quantity === 1 && styles.disabled,
                  )}
                  onClick={() => modifyQuantity(quantity - 1)}
                >
                  -
                </span>
              )}
              <span className={styles.quantity}>{quantity}</span>
              {data.subscription.status === 'active' && (
                <span
                  className={styles.action}
                  onClick={() => modifyQuantity(quantity + 1)}
                >
                  +
                </span>
              )}
            </div>
          </div>
          <div className={styles.betweenRow}>
            <span className={styles.subtitle}>Next payment</span>
            <div>${payment}</div>
          </div>
          <div className={styles.betweenRow}>
            <label
              className={styles.subtitle}
              htmlFor='date_next_charge_scheduled'
            >
              Next order date
            </label>

            <div className={styles.fieldContainer}>
              <input
                id='date_next_charge_scheduled'
                className={c(
                  styles.inputField,
                  errors.date_next_charge_scheduled && styles.inputError,
                )}
                defaultValue={data?.subscription?.date_next_charge_scheduled?.slice(
                  0,
                  10,
                )}
                placeholder='Next Charge'
                type='date'
                disabled={data.subscription.status !== 'active'}
                {...register('date_next_charge_scheduled', {
                  required: true,
                })}
              />
              {errors.date_next_charge_scheduled?.type === 'required' && (
                <div className={c(styles.formError, 'ps-2')}>
                  Please enter a date
                </div>
              )}
            </div>
          </div>
          <div className={styles.delivery}>
            <span className={styles.subtitle}>Deliver to</span>
            <span className={styles.address}>{address}</span>
            <span>Reach out to support@tinyhealth.com to edit</span>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            width='full'
            type='submit'
            loading={isSubmitting}
            disabled={!isValid}
          >
            Save changes
          </Button>
          <LinkButton
            width='full'
            href={`/account/subscription/${subscriptionId}/cancellation`}
            variant='transparent'
          >
            Cancel subscription
          </LinkButton>
        </div>
      </form>
    </div>
  );
};

export default SubscriptionDetail;
