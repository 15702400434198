import { useNavigate } from 'react-router-dom';
import c from 'classnames';

import { TextLink } from 'components';
import { Typography, TextColor } from '@repo/ui';

import styles from './ConditionMetricCard.module.scss';
import { ConditionMetricPill } from './ConditionMetricPill';

export const ConditionMetricCard = ({
  title,
  evaluationCounts,
  action_items,
  link,
}: {
  title: string;
  evaluationCounts?: {
    great: number;
    okay: number;
    support: number;
    improve: number;
  };
  action_items?: number;
  link: string;
}) => {
  const navigate = useNavigate();
  const navigateToLink = () => {
    navigate(link);
  };

  let action_item_text =
    action_items + ` condition action${action_items === 1 ? '' : 's'}`;

  return (
    <div className={c(styles.card)} onClick={navigateToLink}>
      <div className={styles.content}>
        <Typography variant='heading-m'>{title}</Typography>
        <div className={styles.evaluationRow}>
          {!!evaluationCounts && (
            <div className='flex'>
              <ConditionMetricPill
                good={evaluationCounts.great}
                okay={evaluationCounts.okay}
                improve={evaluationCounts.improve}
                support={evaluationCounts.support}
              />
            </div>
          )}
        </div>
        <div className={styles.footerLinks}>
          <div className={styles.link}>
            <TextLink
              label={action_item_text}
              color={TextColor.PURPLE}
              contentOnly
            />
          </div>
          <div>
            <TextLink
              label='See insights'
              iconNameRight='chevronForward'
              color={TextColor.PURPLE}
              contentOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionMetricCard;
