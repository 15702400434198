import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { differenceInDays, format } from 'date-fns';

import { CONSULT_ACTIVE_STATUS, LINKS } from 'assets';
import { kitResultsViewable, parseDate } from 'helpers';
import useConsults from 'services/general/consultsList';
import useUpdateKitDates from './useUpdateKitDates';
import {
  getShouldHideConsults,
  getSortedKitsForTinyAccountId,
} from 'store/kits';
import { getIsAnyTinyPlus, getTinyAccountById } from 'store/account';
import Kit, { KitType } from 'types/Kit';

import { CheckIcon } from 'views/Information/NextStep';
import { HomeResultsStatus } from 'views/Home/HomeContent/HomeNextSteps/HomeNextSteps';
import { TextLink, Typography } from 'components';

export const useTinyAccountForHome = (tinyAccountId: string | null) => {
  const tinyAccount = useSelector(getTinyAccountById(tinyAccountId));
  const kits = useSelector(getSortedKitsForTinyAccountId(tinyAccountId));
  const hasMultipleKits = kits?.length > 1;
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const hasMembership = isAnyTinyPlus;
  const kitMutation = useUpdateKitDates({ onlyOptimistic: true, silent: true });

  const latestKit = useMemo(() => (kits?.length ? kits[0] : null), [kits]);
  const practitionerId = latestKit?.practitioner_id;
  const shouldHideConsults = useSelector(getShouldHideConsults(latestKit?.id));
  const daysSinceResultsReady = useMemo(
    () =>
      latestKit?.date_results_ready
        ? differenceInDays(new Date(), parseDate(latestKit.date_results_ready))
        : null,
    [latestKit],
  );
  const viewableKits = useMemo(() => {
    if (!latestKit) return [];
    const resultsViewableKits = kits?.filter(
      kit => kitResultsViewable(kit) && !!kit.date_survey_completed,
    );
    const latestGutTest = kits?.find(
      kit => kit.sampling_type === KitType.STOOL,
    );
    const latestVaginalTest = kits?.find(
      kit => kit.sampling_type === KitType.VAGINAL,
    );
    //check if there are 2 "latest" kits (gut and vaginal), if not we just return the viewable kits as normal
    if (!latestGutTest || !latestVaginalTest) {
      return resultsViewableKits;
    }
    //we add to the kits list the one that is not the absolute latest if it doesn't show results yet
    if (
      latestGutTest.id === latestKit.id &&
      !kitResultsViewable(latestVaginalTest)
    ) {
      return [latestVaginalTest, ...resultsViewableKits];
    }
    if (
      latestVaginalTest.id === latestKit.id &&
      !kitResultsViewable(latestGutTest)
    ) {
      return [latestGutTest, ...resultsViewableKits];
    }
    return resultsViewableKits;
  }, [kits, latestKit]);

  //CONSULTS
  const { data: consultsList } = useConsults();
  const consultsCompletedForLatestKit = useMemo(() => {
    if (!latestKit) return null;
    return consultsList?.filter(
      consult =>
        consult.status.includes('completed') && consult.kit_id === latestKit.id,
    );
  }, [consultsList, latestKit]);
  const upcomingConsult = useMemo(
    () =>
      consultsList.find(
        consult =>
          CONSULT_ACTIVE_STATUS.includes(consult.status) &&
          consult.kit_id === latestKit?.id,
      ),
    [consultsList, latestKit],
  );

  //STEPS LOGIC
  const stepsList = useMemo(() => {
    //..CouldBeActive means the requirements of the step alone are met
    //..IsActive means the step is also not blocked by previous steps
    const activateIsActive = !latestKit; //it is not blocked by Create profile

    const infoCouldBeActive = !latestKit?.date_survey_completed;
    const infoIsActive = infoCouldBeActive && !activateIsActive;
    const infoIsBlocking = //means the results are ready but the surveys are not completed by the user
      !latestKit?.date_survey_completed && kitResultsViewable(latestKit);

    const resultsCouldBeActive =
      kitResultsViewable(latestKit) && !latestKit?.date_results_step_clicked;
    const resultsIsActive = resultsCouldBeActive && !infoIsActive;
    const resultsIsCompleted =
      kitResultsViewable(latestKit) && !!latestKit?.date_results_step_clicked;

    const consultsCouldBeActive =
      !practitionerId &&
      !consultsCompletedForLatestKit?.length &&
      daysSinceResultsReady !== null &&
      daysSinceResultsReady !== undefined &&
      daysSinceResultsReady < 30;
    const consultsIsActive =
      consultsCouldBeActive &&
      resultsIsCompleted &&
      !infoIsBlocking &&
      !infoIsActive &&
      !activateIsActive;
    const consultIsCheckedOff =
      resultsIsCompleted && !!consultsCompletedForLatestKit?.length;
    //if it "shouldHideConsults" then we consider the step completed (it will be hidden) to unblock the following steps
    const consultsIsCompleted =
      shouldHideConsults ||
      (resultsIsCompleted &&
        (!!consultsCompletedForLatestKit?.length ||
          (!!daysSinceResultsReady && daysSinceResultsReady >= 30)));
    const practitionerIsCompleted = //is completed automatically if the user has a practitioner once it is time for this step
      !!practitionerId &&
      resultsIsCompleted &&
      !infoIsActive &&
      !infoIsBlocking &&
      !activateIsActive;
    const discussStepIsCompleted = practitionerId
      ? practitionerIsCompleted
      : consultsIsCompleted;

    const actionsCouldBeActive = !latestKit?.date_actions_step_clicked;
    const actionsIsActive =
      actionsCouldBeActive &&
      discussStepIsCompleted &&
      !resultsIsActive &&
      !infoIsBlocking &&
      !infoIsActive &&
      !activateIsActive;

    const trackIsActive =
      !actionsIsActive &&
      discussStepIsCompleted &&
      !resultsIsActive &&
      !infoIsBlocking &&
      !infoIsActive &&
      !activateIsActive;

    const getResultStatus = () => {
      if (!latestKit) return <CheckIcon isCompleted={false} />;
      if (latestKit?.date_in_transit_to_lab && !kitResultsViewable(latestKit))
        return <HomeResultsStatus text='Lab analysis' />;
      if (
        kitResultsViewable(latestKit) &&
        !latestKit?.date_results_step_clicked
      )
        return <HomeResultsStatus text='Ready' />;
      if (kitResultsViewable(latestKit) && latestKit?.date_results_step_clicked)
        return <CheckIcon isCompleted={true} />;
      return <CheckIcon isCompleted={false} />;
    };
    const getDiscussStepStatus = () => {
      if (!practitionerId && consultsIsActive && upcomingConsult)
        return <HomeResultsStatus text='Upcoming' />;
      return (
        <CheckIcon
          isCompleted={
            practitionerId ? practitionerIsCompleted : consultIsCheckedOff
          }
        />
      );
    };

    const updateKitDate = async (key: keyof Kit) => {
      //only update if the key doesn't have a date already set
      if (!latestKit || !!latestKit[key]) return;
      const editObject = {
        id: latestKit?.id,
        [key]: format(new Date(), 'yyyy-MM-dd'),
      };
      await kitMutation.mutateAsync(editObject);
    };

    if (!tinyAccount) return [];
    return [
      {
        title: 'Activate kit',
        isActive: activateIsActive,
        Status: <CheckIcon isCompleted={!!latestKit} />,
        clickable: {
          text: 'Activate Kit',
          url: '/activate',
        },
      },
      {
        title: 'Tell us your health story',
        isActive: infoIsActive,
        Status: (
          <CheckIcon
            isCompleted={!!latestKit?.date_survey_completed}
            isBlocked={infoIsBlocking}
          />
        ),
        clickable: {
          text: 'Complete info',
          url: `/information/${latestKit?.id}`,
        },
        isBlocking: infoIsBlocking,
        descriptionBlocking: `Our lab has finished processing your sample but we’re not able to publish your report until all surveys are completed.`,
        isCompleted: !!latestKit?.date_survey_completed,
        inlineLinkPostCompletion: {
          text: 'View',
          url: `/results/${latestKit?.id}/surveys`,
        },
      },
      {
        title: 'Review results',
        isActive: resultsIsActive,
        Status: getResultStatus(),
        clickable: {
          text: 'View results',
          url: `/results/${latestKit?.id}`,
          onClick: () => updateKitDate('date_results_step_clicked'),
        },
        isCompleted:
          kitResultsViewable(latestKit) &&
          !!latestKit?.date_results_step_clicked,
        inlineLinkPostCompletion: {
          text: 'View',
          url: `/results/${latestKit?.id}`,
        },
      },
      ...(shouldHideConsults
        ? []
        : [
            {
              title: `Discuss results with ${
                practitionerId ? 'practitioner' : 'specialist'
              }`,
              isActive: practitionerId ? false : consultsIsActive,
              Status: getDiscussStepStatus(),
              clickable: practitionerId
                ? undefined
                : {
                    text: 'Book a coaching session',
                    url: `/results/${latestKit?.id}/consults/book`,
                  },
              isCompleted: practitionerId
                ? practitionerIsCompleted
                : consultsIsCompleted,
              CompletedContent: !practitionerId ? null : (
                <Typography variant='label'>
                  We have already notified your{' '}
                  <TextLink label='practitioner' size='label' to='/account' />.
                </Typography>
              ),
            },
          ]),
      {
        title: 'Implement Action Plan',
        isActive: actionsIsActive,
        Status: (
          <CheckIcon isCompleted={!!latestKit?.date_actions_step_clicked} />
        ),
        clickable: {
          text: 'Take Action',
          url: `/action-plan/${latestKit?.id}`,
          onClick: () => updateKitDate('date_actions_step_clicked'),
        },
        isCompleted: !!latestKit?.date_actions_step_clicked,
        inlineLinkPostCompletion: {
          text: 'View',
          url: `/action-plan/${latestKit?.id}`,
        },
      },
      {
        title: hasMembership ? 'Take next sample' : 'Track your progress',
        isActive: trackIsActive,
        Status: <CheckIcon isCompleted={false} />,
        clickable: hasMembership
          ? {
              text: 'Activate Kit',
              url: '/activate',
              isExternal: false,
            }
          : {
              text: 'Explore membership',
              url: LINKS.membership,
              isExternal: true,
            },
        description: tinyAccount?.next_sample_date
          ? `We recommend you resample on ${format(
              parseDate(tinyAccount?.next_sample_date) ?? new Date(),
              'MMM d, yyyy',
            )}`
          : '',
      },
    ];
  }, [tinyAccount, latestKit]);

  const getDisplayName = useCallback(
    (useLowercase?: boolean) => {
      const displayName = tinyAccount?.first_name
        ? `${tinyAccount?.first_name}'s`
        : 'Your';
      return useLowercase && !tinyAccount?.first_name
        ? displayName.toLowerCase()
        : displayName;
    },
    [tinyAccount?.first_name],
  );

  return {
    tinyAccount,
    hasMultipleKits,
    kits,
    viewableKits,
    latestKit,
    getDisplayName,
    hasMembership,
    stepsList,
  };
};
