import c from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSuspenseQueries } from '@tanstack/react-query';

import { Icon, Typography } from '@repo/ui';
import { getEvaluationIconFromColor } from '../Insights/EvaluationGroupsSummary/EvaluationGroupsSummary';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { getActionPlanItemsQuery } from 'services/actionPlan/actionPlanData';
import styles from './ConditionMetricRow.module.scss';
import { ActionPlanItem } from 'types/ActionPlan';
import type Metric from 'types/Metric';
import { userValueFormat } from 'helpers';

export const getActionsForSubMetric = (
  actions: ActionPlanItem[],
  subMetricId?: number | string,
) => {
  if (!subMetricId) return actions;
  const numericIdToUse =
    typeof subMetricId === 'string' ? parseInt(subMetricId) : subMetricId;
  return actions.filter((item: ActionPlanItem) =>
    item.desired_outcomes?.some(
      outcome =>
        outcome.section === 'Gut Vaginal Health' &&
        outcome.gut_vaginal_health &&
        outcome.gut_vaginal_health.id === numericIdToUse,
    ),
  );
};

export const ConditionMetricRow = ({
  metric,
  row,
}: {
  metric: Metric;
  row: number;
}) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();

  const [{ data: allActions }] = useSuspenseQueries({
    queries: [getActionPlanItemsQuery(currentKitId)],
  });

  const actionItems = getActionsForSubMetric(allActions, metric.metric_id);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navigateToSubmetric = () => {
    navigate(
      `/results/${currentKitId}/insights/all-insights/${metric.metric_id}`,
      { state: { from: pathname } },
    );
  };

  return (
    <div
      className={c(styles.metricRow, row !== 0 && styles.borderTop)}
      onClick={() => {
        navigateToSubmetric();
      }}
    >
      <div className={styles.metricName}>
        <Icon
          name={getEvaluationIconFromColor(metric.evaluation_color)}
          size='s'
          className={styles[metric.evaluation_color]}
        />
        <Typography variant='body-s' className={styles.textDark}>
          {metric.name}
        </Typography>
        <Typography variant='body-s' className={styles.actions}>
          {actionItems.length ? `${actionItems.length} metric actions` : null}
        </Typography>
      </div>

      <div className={styles.metricData}>
        <div
          className={c(
            styles.relAbun,
            styles[`text-${metric.evaluation_color}`],
          )}
        >
          {userValueFormat(metric.user_value, metric.data_type)}
        </div>
        <Icon name='chevronForward' size='xs' />
      </div>
    </div>
  );
};
