import Kit from '@repo/types/Kit';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName } from 'assets/constants';

export type UnregisteredKitFormValues = {
  kitId: string;
};

const fetchUnregisteredKit = async (
  kitId?: string | null,
): Promise<Kit | null> => {
  if (!kitId) return null;
  const response = await API.get(
    apiName,
    `/public/kits/find/${kitId.replace(/\s+/g, '')}`,
  );
  return response.result;
};

export const getUnregisteredKitQuery = (kitId?: string | null) => ({
  queryKey: ['unregisteredKit', kitId],
  queryFn: () => fetchUnregisteredKit(kitId),
  enabled: !!kitId,
});

const useUnregisteredKit = (kitId?: string | null) => {
  return useQuery<Kit | null>(getUnregisteredKitQuery(kitId));
};

export default useUnregisteredKit;
