import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { kitResultsViewable, sortKitsByLatestSampleDate } from 'helpers';
import { signOut } from 'store/user';

import Kit from 'types/Kit';
import { CompletedSurvey } from '../types/Survey';
import { getKits } from './shared';
import { RootState } from './store';
import { getConsultCreditsCount, getTinyAccounts } from './account';
interface KitsState {
  kits: Kit[];
  pdfRequests: { [key: string]: boolean };
}

export const initialState: KitsState = {
  kits: [],
  pdfRequests: {},
};

// Reducer
const kitsSlice = createSlice({
  name: 'kits',
  initialState,
  reducers: {
    setKits(state, action: PayloadAction<Kit[]>) {
      state.kits = action.payload;
    },
    setRequestedPDFForKit(state, action: PayloadAction<string>) {
      state.pdfRequests[action.payload] = true;
    },
    setSurveyCompleted(state, action: PayloadAction<CompletedSurvey>) {
      const { form_id, kit_id } = action.payload;
      const kitToUpdate = state.kits.find(kit => kit.id === kit_id);
      if (kitToUpdate?.survey_status) {
        const surveyIndex = kitToUpdate.survey_status.pending.findIndex(
          survey => survey.typeform_id === form_id,
        );
        if (surveyIndex !== -1) {
          const movedSurvey = kitToUpdate.survey_status.pending.splice(
            surveyIndex,
            1,
          )[0];
          if (movedSurvey) {
            kitToUpdate.survey_status.completed.push(movedSurvey);
          }
        }
      }
    },
    revertSurveyCompleted(state, action: PayloadAction<CompletedSurvey>) {
      const { form_id, kit_id } = action.payload;
      const kitToUpdate = state.kits.find(kit => kit.id === kit_id);
      if (kitToUpdate?.survey_status) {
        const surveyIndex = kitToUpdate.survey_status.completed.findIndex(
          survey => survey.typeform_id === form_id,
        );
        if (surveyIndex !== -1) {
          const movedSurvey = kitToUpdate.survey_status.completed.splice(
            surveyIndex,
            1,
          )[0];
          if (movedSurvey) {
            kitToUpdate.survey_status.pending.push(movedSurvey);
          }
        }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(signOut, (state, action) => {
      return initialState;
    });
  },
});

// Selectors
const getKitsState = (state: RootState) => state.kits;

export const getKitsForTinyAccountId = (tinyAccountId: string | null) =>
  createSelector(getKits, kits =>
    kits.filter(kit => kit.tinyaccount_id === tinyAccountId),
  );

export const getSortedKitsForTinyAccountId = (tinyAccountId: string | null) =>
  createSelector(getKitsForTinyAccountId(tinyAccountId), kits => {
    if (!tinyAccountId || !kits) return [];
    return sortKitsByLatestSampleDate(kits);
  });
export const getLatestKitForTinyAccountId = (tinyAccountId: string | null) =>
  createSelector(getSortedKitsForTinyAccountId(tinyAccountId), kits => {
    if (!kits || !kits.length) return null;
    return kits[0];
  });
export const getLatestKits = () =>
  createSelector([getTinyAccounts, getKits], (tinyAccounts, kits) => {
    const latestKits: Kit[] = [];
    tinyAccounts?.forEach(tinyAccount => {
      const thisAccountKits = kits.filter(
        kit => kit.tinyaccount_id === tinyAccount.id,
      );
      const sortedKitsForThisAccount =
        sortKitsByLatestSampleDate(thisAccountKits);
      if (sortedKitsForThisAccount[0]) {
        //get the latest (first) vaginal and gut test
        const firstKit = sortedKitsForThisAccount[0];
        latestKits.push(firstKit);
        const savedType = firstKit.sampling_type;
        //get the first of the other type
        for (let i = 0; i < sortedKitsForThisAccount.length; i++) {
          const kit = sortedKitsForThisAccount[i];
          if (!kit) continue;
          if (sortedKitsForThisAccount[i]?.sampling_type !== savedType) {
            latestKits.push(kit);
            break;
          }
        }
      }
    });
    return latestKits;
  });

export const getKitById = (kitId?: string) =>
  createSelector(getKits, kits => kits.find(kit => kit.id === kitId));

export const getKitHasResultsById = (kitId: string) =>
  createSelector(getKitById(kitId), kit =>
    kit ? kitResultsViewable(kit) : false,
  );
export const getKitIsProKitById = (kitId?: string) =>
  createSelector(getKitById(kitId), kit => !!kit?.variant);

export const getSurveysStatusForKitId = (kitId?: string) =>
  createSelector(getKitById(kitId), kit => kit?.survey_status);

export const getPdfUrlForKitId = (kitId?: string) =>
  createSelector(getKitById(kitId), kit => kit?.pdf_url);

export const getKitBelongsToAccount = (kitId?: string | null) =>
  createSelector(getKits, kits => !!kits.find(kit => kit.id === kitId));

export const getKitPDFRequested = (kitId?: string | null) =>
  createSelector(getKitsState, state => kitId && !!state.pdfRequests[kitId]);

export const getBabyGutKitsForTinyAccountId = (tinyAccountId: string | null) =>
  createSelector(getKitsForTinyAccountId(tinyAccountId), kits => {
    if (!tinyAccountId || !kits || !kits.length) return [];
    return kits.filter(
      kit =>
        kit.sampling_type === 'stool' &&
        kit.age_at_sampling &&
        kit.age_at_sampling < 395 &&
        kitResultsViewable(kit),
    );
  });
export const getKitsForMicrobiomeBarList = (kitId?: string) =>
  createSelector([getKitById(kitId), getKits], (currentKit, kits) => {
    const filteredKits = kits.filter(
      kit =>
        kit.tinyaccount_id === currentKit?.tinyaccount_id &&
        kit.sampling_type === currentKit?.sampling_type &&
        kitResultsViewable(kit) &&
        kit?.sample_date &&
        currentKit?.sample_date &&
        kit?.sample_date <= currentKit?.sample_date,
    );
    return sortKitsByLatestSampleDate(filteredKits);
  });
export const getHasPractitionerHideConsult = (kitId?: string) =>
  createSelector(getKitById(kitId), kit => !!kit?.practitioner?.hide_consults);
export const getHasPartnerHideConsult = (kitId?: string) =>
  createSelector(getKitById(kitId), kit => !!kit?.partner?.hide_consults);
export const getHasAnyHideConsults = (kitId?: string) =>
  createSelector(
    [getHasPractitionerHideConsult(kitId), getHasPartnerHideConsult(kitId)],
    (hasPractitionerHideConsult, hasPartnerHideConsult) => {
      return hasPractitionerHideConsult || hasPartnerHideConsult;
    },
  );
export const getShouldHideConsults = (kitId?: string) =>
  createSelector(
    [
      getHasPartnerHideConsult(kitId),
      getHasPractitionerHideConsult(kitId),
      getConsultCreditsCount,
    ],
    (
      hasPartnerHideConsult,
      hasPractitionerHideConsult,
      consultCreditsCount,
    ) => {
      if (hasPartnerHideConsult) return true; //partner config can't be overridden
      //having consult credits override practitioner config
      return hasPractitionerHideConsult && !consultCreditsCount;
    },
  );

export const {
  setKits,
  setRequestedPDFForKit,
  setSurveyCompleted,
  revertSurveyCompleted,
} = kitsSlice.actions;
export default kitsSlice.reducer;
