import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import c from 'classnames';

import useSubscriptions from 'services/general/subscriptionsList';
import useFeature from 'services/features/useFeature';
import useSignOut from 'services/auth/useSignOut';
import { LINKS } from 'assets/constants';
import {
  getFullName,
  getAccountEmail,
  getIsTinyPlus,
  getParsedMembershipDate,
  getIsInProgram,
  getIsAnyTinyPlus,
} from 'store/account';

import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  LinkButton,
  Typography,
} from 'components';
import AccountNameModal from './AccountNameModal';
import AccountEmailModal from './AccountEmailModal';
import AccountField from './AccountField/AccountField';
import SubscriptionField from './SubscriptionField/SubscriptionField';
import { Practitioners } from './Practitioners/Practitioners';

import styles from './Account.module.scss';

const Account = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const fullName = useSelector(getFullName);
  const email = useSelector(getAccountEmail);
  const isTinyPlus = useSelector(getIsTinyPlus);
  const isAnyTinyPlus = useSelector(getIsAnyTinyPlus);
  const isProgram = useSelector(getIsInProgram);
  const membershipDate = useSelector(getParsedMembershipDate);
  const formattedDate = membershipDate
    ? format(membershipDate, 'MMM yyyy')
    : '';
  const [showModalName, setShowModalName] = useState<boolean>(false);
  const [showModalEmail, setShowModalEmail] = useState<boolean>(false);
  const { data: subscriptionsList } = useSubscriptions('kits');
  const { isFeatureEnabled: showSubscriptionsV2 } =
    useFeature('subscriptions_v2');

  const editName = () => {
    setShowModalName(true);
  };
  const editEmail = () => {
    setShowModalEmail(true);
  };

  const viewVersionLog = () => {
    window.open(LINKS.versionLog, '_blank');
  };
  const viewSubscriptions = () => {
    navigate(`/account/subscriptions`);
  };
  const toggleModalName = () => setShowModalName(prev => !prev);
  const toggleModalEmail = () => setShowModalEmail(prev => !prev);

  return (
    <>
      {fullName && (
        <AccountNameModal toggle={toggleModalName} showModal={showModalName} />
      )}
      {email && (
        <AccountEmailModal
          toggle={toggleModalEmail}
          showModal={showModalEmail}
        />
      )}
      <div className={styles.accountContainer}>
        <Typography variant='heading-xl' className='mb-4'>
          Account
        </Typography>
        <div className={styles.section}>
          <AccountField title='Name' action={editName} actionName='Edit'>
            <span className={styles.value}>{fullName}</span>
          </AccountField>
          <AccountField title='Email' action={editEmail} actionName='Edit'>
            <span className={styles.value}>{email}</span>
          </AccountField>
        </div>

        <div className={styles.section}>
          <AccountField
            title={showSubscriptionsV2 ? 'Membership' : 'Membership level'}
            action={
              showSubscriptionsV2 && isAnyTinyPlus
                ? viewSubscriptions
                : undefined
            }
            actionName={showSubscriptionsV2 && isAnyTinyPlus ? 'Manage' : ''}
          >
            {isAnyTinyPlus ? (
              <div className='flex flex-column'>
                <span className={styles.value}>
                  Tiny+ member
                  {formattedDate && <span>{` since ${formattedDate}`}</span>}
                </span>
                <span className={c(styles.value, 'mt-2')}>
                  {`Access your Tiny+ members-only rate of $${
                    isTinyPlus ? 149 : 169
                  } per kit: `}
                  <LinkButton
                    label='Gut kit'
                    href={
                      isTinyPlus
                        ? LINKS.checkoutWithKitTinyPlus
                        : LINKS.checkoutWithKitTinyPlusProgramOrProactive +
                          '&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=accountpage'
                    }
                    external
                    variant={ButtonVariants.TEXT}
                    size={ButtonSizes.M}
                    color={ButtonColors.PURPLE}
                  />
                  {`, `}
                  <LinkButton
                    label='Vaginal kit.'
                    href={
                      isTinyPlus
                        ? LINKS.checkoutWithKitTinyPlusVaginal
                        : LINKS.checkoutWithKitTinyPlusProgramOrProactiveVaginal +
                          '&utm_source=tinyhealth&utm_medium=webapp&utm_campaign=accountpage'
                    }
                    external
                    variant={ButtonVariants.TEXT}
                    size={ButtonSizes.M}
                    color={ButtonColors.PURPLE}
                  />
                </span>
              </div>
            ) : (
              <div className='flex flex-column'>
                <div className={styles.value}>Basic </div>
                <LinkButton
                  label='Learn more about Tiny+ memberships'
                  href={LINKS.store}
                  external
                  variant={ButtonVariants.TEXT}
                  size={ButtonSizes.M}
                  color={ButtonColors.PURPLE}
                />
              </div>
            )}
          </AccountField>
        </div>

        {!showSubscriptionsV2 && !isProgram && !!subscriptionsList?.length && (
          <div className={styles.section}>
            <AccountField title='Subscriptions'>
              <div className={styles.subscriptionsContainer}>
                {subscriptionsList.map(subscription => (
                  <SubscriptionField
                    subscription={subscription}
                    key={subscription.id}
                  />
                ))}
              </div>
            </AccountField>
          </div>
        )}

        <div className={styles.section}>
          <AccountField title='Practitioner'>
            <div className='flex flex-column'>
              <span className={styles.value}>
                {`If your practitioner is a Tiny Health Member you can add them here, This will give them access to your results, surveys and action plan.`}
              </span>
              <Practitioners />
            </div>
          </AccountField>
        </div>
        <div className={styles.section}>
          <AccountField
            title='Version Log'
            action={viewVersionLog}
            actionName={'View'}
          >
            <span className={styles.value}>
              Email hello@tinyhealth.com to update to the latest system version.
            </span>
          </AccountField>
        </div>
        <div className='mt-auto pt-4'>
          <Button
            label='Sign Out'
            size={ButtonSizes.M}
            variant={'outline'}
            width={'full'}
            iconNameLeft='logOut'
            onClick={signOut}
          />
        </div>
      </div>
    </>
  );
};

export default Account;
