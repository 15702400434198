import Partner from './Partner';
import PractitionerUser from './PractitionerUser';
import { KitSurveyStatus } from './Survey';
import { TinyAccountId } from './TinyAccount';

export enum KitType {
  STOOL = 'stool',
  VAGINAL = 'vaginal',
}
export enum KitStatus {
  ACTIVATED = 'activated',
  CONFIRM_KIT_RETURNED_TO_LAB_BY_CUSTOMER = 'confirm_kit_returned_to_lab_by_customer',
  DELIVERED_TO_CUSTOMER = 'delivered_to_customer',
  DELIVERED_TO_LAB = 'delivered_to_lab',
  EXPERT_REVIEW_COMPLETE = 'expert_review_complete',
  INITIALIZED = 'initialized',
  IN_TRANSIT_TO_CUSTOMER = 'in_transit_to_customer',
  IN_TRANSIT_TO_LAB = 'in_transit_to_lab',
  LABEL_EXPIRY_EMAIL_SENT = 'label_expiry_email_sent',
  PIPELINE_COMPLETE = 'pipeline_complete',
  SEQUENCES_DELIVERED = 'sequences_delivered',
  RESULTS_READY = 'results_ready',
  RESULTS_READY_PRACTITIONER = 'results_ready_practitioner',
  RETURN_MISSING = 'return_missing',
  VOID = 'void',
  //Below here are deprecated void statuses
  VOID_CUSTOMER_REFUND = 'void_customer_refund',
  VOID_INTERNAL = 'void_internal',
  VOID_LAB_ERROR = 'void_lab_error',
  VOID_LOST_IN_MAIL = 'void_lost_in_mail',
  VOID_RETURN_LABEL_EXPIRED = 'void_return_label_expired',
  VOID_SAMPLE_EXPIRED = 'void_sample_expired',
  VOID_SHIPPO_SHIPMENT_FAILED = 'void_shippo_shipment_failed',
}

export type KitId = string;
interface Kit {
  id: KitId;
  lab_partner: string;
  status?: KitStatus;
  tinyaccount_id?: TinyAccountId;
  sampling_type?: KitType;
  sample_date?: string;
  sample_label?: string | null;
  is_resample?: boolean | number | null;
  surveys_complete?: number;
  adult_gut_sample_survey?: string | null;
  adult_vaginal_sample_survey?: string | null;
  baby_gut_sample_survey?: string | null;
  age_at_sampling?: number | null;
  date_activated?: string | null;
  date_survey_completed?: string | null;
  date_in_transit_to_lab?: string | null;
  date_delivered_to_lab?: string | null;
  date_sequences_delivered?: string | null;
  date_pipeline_complete?: string | null;
  date_delivered_to_customer?: string | null;
  date_results_ready?: string | null;
  date_results_ready_practitioner?: string | null;
  date_expert_review_complete?: string | null;
  days_pp?: number | null;
  feeding_method?: string | null;
  post_partum?: boolean | number | null;
  pregnant?: boolean | number | null;
  tracking_number_to_user?: unknown | null;
  ttc?: boolean | number | null;
  week_of_pregnancy?: number | null;
  pdf_url?: string | null;
  practitioner?: PractitionerUser;
  partner?: Partner;
  partner_id?: string | null;
  survey_status?: KitSurveyStatus;
  date_results_step_clicked?: string | null;
  date_actions_step_clicked?: string | null;
  variant?: KitVariant | null;
  sci_version?: string | null;
  sci_initial_version?: string | null;
  practitioner_id?: string | null;
}

export type KitVariant = {
  date_delivered_to_lab: string | null;
  date_sequences_delivered: string | null;
};
export default Kit;
