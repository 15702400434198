import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getMajorVersion } from 'helpers';
import { useSystemInfo } from './useSystemInfo';
import { getKits } from 'store/shared';
import { KitStatus } from 'types/Kit';

export const usePreviousKitMajorVersion = () => {
  const { latestSciVersion } = useSystemInfo();
  const kits = useSelector(getKits);

  const validMajorVersions: number[] = useMemo(() => {
    if (!kits?.length) return [];
    // Extract major versions, filtering out invalid or NaN values
    return kits
      .filter(kit => kit?.status !== KitStatus.VOID)
      .map(kit => getMajorVersion(kit?.sci_version ?? '') ?? 0)
      .filter(version => !isNaN(version));
  }, [kits]);
  const oldestMajorVersion = useMemo(() => {
    let minMajorVersion = 0;
    // If we have valid versions, get the minimum
    if (validMajorVersions.length > 0) {
      minMajorVersion = Math.min(...validMajorVersions);
    }
    return minMajorVersion;
  }, [validMajorVersions]);

  const hasMultipleVersions = useMemo(
    () => new Set(validMajorVersions).size > 1,
    [validMajorVersions],
  );

  return {
    oldestVersion: oldestMajorVersion,
    hasOlderKit: oldestMajorVersion != getMajorVersion(latestSciVersion ?? ''),
    validMajorVersions,
    hasMultipleVersions,
  };
};
