import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';
import { apiName } from 'assets';
import { Kit } from '@repo/types/Kit.js';
import TinyAccount from '@repo/types/TinyAccount.js';

type PartnerActivateKitParams = {
  kit: Partial<Kit>;
  tinyAccount: Partial<TinyAccount>;
};

const partnerActivateKit = async ({
  kit,
  tinyAccount,
}: PartnerActivateKitParams) => {
  if (!kit) throw new Error('No Kit provided');
  if (!tinyAccount) throw new Error('No tiny account provided');

  const tinyAccountData = {
    first_name: tinyAccount.first_name,
    last_name: tinyAccount.last_name,
    birthdate: tinyAccount.birthdate,
    sex: tinyAccount.sex,
    email: tinyAccount.email,
    expected_due_date: tinyAccount.expected_due_date,
    delivery_date: tinyAccount.delivery_date,
    ttc_date: tinyAccount.ttc_date,
  };

  const response = await API.post(apiName, `/public/kits/partner-activate`, {
    body: {
      kit,
      tinyaccount: tinyAccountData,
    },
  });
  return response;
};

const usePartnerActivateKit = () => {
  const queryClient = useQueryClient();
  const kitMutation = useMutation({
    mutationFn: (params: PartnerActivateKitParams) => {
      return partnerActivateKit(params);
    },
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['unregisteredKit', variables.kit.id],
        refetchType: 'all',
      });
    },
  });
  return kitMutation;
};

export default usePartnerActivateKit;
