import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getHasModalOpened, setModalOpened } from 'store/modal';
import { Condition } from 'types/Conditions';

import { Button, ButtonSizes } from 'components';
import { Icon } from '@repo/ui';
import { ConditionsList } from './ConditionsList';

import styles from './Conditions.module.scss';

type ConditionsSummaryViewProps = {
  type: string;
  conditions: Condition[];
};

export const ConditionsInfoModal = ({
  toggle,
  showModal,
  onModalOpen,
}: {
  toggle(): void;
  showModal: boolean;
  onModalOpen?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(showModal);

  useEffect(() => {
    setIsOpen(showModal);
  }, [setIsOpen, showModal]);

  const onToggle = () => {
    toggle();
    onModalOpen && onModalOpen();
    setIsOpen(prevState => !prevState);
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} fullscreen='md'>
      <ModalHeader toggle={onToggle}></ModalHeader>
      <ModalBody>
        <div className='flex flex-column align-items-center gap-4'>
          <div className={styles.circledIcon}>
            <Icon name='alertTriangleFilled' size='l' />
          </div>
          <div className='text-center'>
            <h6 className={c('mb-2', styles.title)}>
              This test does not diagnose any health conditions
            </h6>
            <span className={styles.secondaryText}>
              This section shows metrics that may be associated with these
              conditions. Lifestyle, genetics, and other factors may also
              influence the presence of these conditions.
            </span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export const ConditionsSummaryView = ({
  type,
  conditions,
}: ConditionsSummaryViewProps) => {
  const dispatch = useDispatch();
  const { currentKitId } = useCurrentKitAndTinyAccount();

  const [showModal, setShowModal] = useState(false);
  const toggleConditionsInfoModal = () => {
    setShowModal(!showModal);
  };
  const conditionsDisclaimerModal = {
    name: 'conditionsDisclaimer',
    props: { kitId: currentKitId },
  };
  const hasOpenedConditionsTable = useSelector(
    getHasModalOpened(conditionsDisclaimerModal),
  );
  const handleConditionsDisclaimerModalOpened = async () => {
    dispatch(setModalOpened(conditionsDisclaimerModal));
  };

  return (
    <>
      <ConditionsInfoModal
        showModal={!hasOpenedConditionsTable || showModal}
        toggle={toggleConditionsInfoModal}
        onModalOpen={handleConditionsDisclaimerModalOpened}
      />
      <section id='conditions' className={styles.conditions}>
        <div className='flex align-items-center justify-between w-100 mb-2'>
          <span
            className={styles.conditionTitle}
          >{`Emerging Research: Condition ${type}`}</span>
          {type === 'Biomarkers' && (
            <Button
              size={ButtonSizes.S}
              variant='tertiary'
              iconNameRight='chevronForward'
              onClick={() => {
                toggleConditionsInfoModal();
              }}
            >
              Have a condition?
            </Button>
          )}
        </div>
        {type === 'Biomarkers' && (
          <p>
            See how your microbiome signature associates with risk of certain
            conditions in later life
          </p>
        )}
        {type === 'Associations' && (
          <p>
            See other associations with conditions, based on emerging research
          </p>
        )}
        <ConditionsList type={type} conditions={conditions} />
      </section>
    </>
  );
};
