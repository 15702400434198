import { Markdown, Typography } from 'components';
import { PartnerKitStepProps } from '../KitActivationForPartners';

export const FinalStep = ({
  setActivationState,
  activationState,
  step,
  setCurrentActivationStep,
}: PartnerKitStepProps) => {
  const { kit } = activationState;
  const markdownContent = kit?.partner?.kit_activation_instructions;
  return (
    <div className='flex flex-column align-items-center gap-4 mt-4'>
      {!!markdownContent ? (
        // <Markdown>{markdownContent.replace(/\\n/gi, '\n \n')}</Markdown>
        <Markdown>{markdownContent}</Markdown>
      ) : (
        <Typography variant='body-m'>
          Your kit has be activated! Your contact will reach out to you when
          your results are ready.
        </Typography>
      )}
    </div>
  );
};
