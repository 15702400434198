import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import c from 'classnames';

import { Icon } from '@repo/ui';
import { KitStep } from 'views/Kits/ActivateKit';

import styles from './Kits.module.scss';

export const ActivationNavigation = ({
  step,
  setCurrentActivationStep,
  startingStep,
  steps,
  kitId,
}: {
  step: number;
  setCurrentActivationStep: (step: number) => void;
  startingStep: number;
  steps: KitStep[];
  kitId?: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const mandatorySteps = useMemo(
    () => steps.filter(step => !step.optional),
    [steps],
  );
  const isOptionalStep = useMemo(
    () => step > mandatorySteps?.length - 1,
    [step, mandatorySteps],
  );

  const navigateBack = () => {
    // second should navigate to /activate when Back is pressed since the 1st step is mostly "automatically completed"
    if (step === startingStep || step === startingStep + 1) {
      if (state?.from) {
        navigate(state.from);
      } else {
        navigate('/activate');
      }
    } else {
      setCurrentActivationStep(step - 1);
    }
  };
  const redirectToResults = () => {
    kitId ? navigate(`/results/${kitId}`) : navigate('/results');
  };

  return (
    <div className={styles.activationNavigation}>
      <div className={styles.navPlaceholder}>
        {!isOptionalStep && (
          <div className={styles.backButton} onClick={navigateBack}>
            <Icon name='chevronBack' />
            Back
          </div>
        )}
      </div>
      <div className={styles.stepsProgress}>
        {mandatorySteps.map((s, idx) => (
          <div
            className={c(
              styles.progressMarker,
              idx < step && styles.filled,
              idx === step && styles.partial,
            )}
            key={idx}
            data-testid='progressMarker'
          ></div>
        ))}
        {isOptionalStep && (
          <div
            className={c(styles.progressMarker, styles.partial)}
            key={step}
            data-testid='progressMarker'
          ></div>
        )}
      </div>
      <div className={styles.navPlaceholder}>
        {isOptionalStep && (
          <div
            className={styles.closeButton}
            onClick={() => redirectToResults()}
          >
            <Icon name='closeSm' />
          </div>
        )}
      </div>
    </div>
  );
};
