import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from 'contexts/analytics/AnalyticsProvider';
import { apiName } from 'assets';
import { API } from 'api';

export const postPartnerSurveySubmitted = async ({
  tinyaccount_id,
  kit_id,
  form_id,
}: {
  tinyaccount_id: string;
  kit_id?: string;
  form_id: string;
}) => {
  const response = await API.post(apiName, `/public/kits/typeform_submit`, {
    body: {
      form_id,
      kit_id: kit_id ?? null,
      tinyaccount_id: tinyaccount_id ?? null,
    },
  });
  return response;
};
const useSubmitFinishedPartnerSurvey = () => {
  const trackEvent = useAnalytics().trackEvent;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (properties: {
      form_id: string;
      tinyaccount_id: string;
      kit_id: string;
    }) => {
      return postPartnerSurveySubmitted(properties);
    },
    onSuccess(data, variables, context) {
      const queryKey = ['unregisteredKit', variables.kit_id];
      queryClient.setQueryData(queryKey, (oldData: any) => ({
        ...oldData,
        date_survey_completed: format(new Date(), 'yyyy-MM-dd'),
      }));
      toast.success('Survey Completed');
      trackEvent('Survey Completed', variables);
    },
    onError: (err, variables, context) => {
      toast.error(
        'There was an error submitting the survey. Please try again.',
      );
      Sentry.captureException(err);
    },
  });
};
export default useSubmitFinishedPartnerSurvey;
