import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import c from 'classnames';

import { getUnregisteredKitQuery } from 'services/kits/useUnregisteredKit';
import { getIsAuthenticated } from 'store/user';
import { AxiosError } from 'axios';

import {
  DesignButton,
  ButtonColors,
  ButtonSizes,
  LinkButton,
  Typography,
} from 'components';
import KitIllustrationSvg from 'assets/images/KitIdIllustration.svg?react';

import styles from './ActivateKit.module.scss';

type UnregisteredKitFormValues = {
  kitId: string;
};

const KitActivationStepNoAuth = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const {
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<UnregisteredKitFormValues>({ mode: 'onBlur' });

  const kitId = watch('kitId') ?? '';
  const { refetch, isSuccess } = useQuery({
    ...getUnregisteredKitQuery(kitId),
    enabled: false,
    retry: 1,
  });

  const handleError = (error: AxiosError | any) => {
    const message =
      error?.response?.data?.message ??
      'Something went wrong, please check the kit ID and try again';
    setError('kitId', { type: 'noKit', message });
    Sentry.captureMessage(message);
  };

  const onSubmit = async (formData: UnregisteredKitFormValues) => {
    try {
      const { data: foundKit, error } = await refetch();

      if (
        !!foundKit?.id &&
        !!foundKit?.partner &&
        !!foundKit?.partner?.portal_config?.disable_kit_activation
      ) {
        navigate(`/partners/${foundKit.id}`);
        return;
      }
      if (isAuthenticated && foundKit?.id) {
        navigate(`/kits/activate/${foundKit.id}`);
        return;
      }
      if (error) {
        handleError(error);
      }
      return foundKit;
    } catch (error: AxiosError | any) {
      handleError(error);
    }
  };

  return (
    <div className={styles.activateContainer}>
      {isSuccess && isSubmitSuccessful && kitId ? (
        <>
          <Typography variant='heading-xl' className='text-center'>
            {`Kit ID: ${kitId}`}
          </Typography>
          <Typography variant='heading-m' className='text-center'>
            Do you already have an account with us?
          </Typography>

          <div className='mt-4 w-100'>
            <LinkButton
              label={"No, I'm new to TinyHealth"}
              href={'/register'}
              color={ButtonColors.GREEN}
              size={ButtonSizes.M}
              className={styles.button}
              width='full'
            />
          </div>
          <div className='mt-2 w-100'>
            <LinkButton
              label={'Yes, login'}
              href={`/kits/activate/${kitId}`}
              color={ButtonColors.LIGHT}
              size={ButtonSizes.M}
              className={styles.button}
              width='full'
            />
          </div>
        </>
      ) : (
        <>
          <Typography variant='heading-2xl' className='text-center'>
            Hello, let’s activate your kit
          </Typography>
          <Typography variant='heading-m' className='text-center'>
            To get started, enter your kit ID below
          </Typography>

          <KitIllustrationSvg className={c(styles.kitIllustration)} />

          <div className='my-3 text-center'>
            <Typography variant='subHeadline' className='grey-text text-center'>
              You will find your ID on your test swab and also in the test box
            </Typography>
          </div>

          <form
            className={c(styles.registerForm, styles.newKitRegistration)}
            onSubmit={handleSubmit(onSubmit)}
            aria-label='kit-activation-form'
          >
            <div className={c('field', styles.fieldContainer)}>
              <input
                id='kitId'
                type='text'
                autoComplete='off'
                className={c(
                  'text-center',
                  styles.inputField,
                  errors.kitId && styles.inputError,
                )}
                placeholder='HMC582'
                {...register('kitId', {
                  required: true,
                  minLength: 6,
                })}
              />
              {errors.kitId?.type === 'minLength' && (
                <div className={styles.formError}>
                  Must input at least 6 characters
                </div>
              )}
              {errors.kitId?.type === 'required' && (
                <div className={styles.formError}>Kit ID is required</div>
              )}
              {errors.kitId?.type === 'noKit' && (
                <div className={styles.formError}>{errors.kitId.message}</div>
              )}
              <DesignButton
                label='Continue'
                type='submit'
                loading={isSubmitting}
                disabled={kitId?.length < 6}
                size={ButtonSizes.M}
                color={ButtonColors.GREEN}
                width={'full'}
                className='mt-4'
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default KitActivationStepNoAuth;
