import styles from './PartnerLogo.module.scss';

export const PartnerLogo = ({ url }: { url?: string }) => {
  if (!url) return <></>;
  return (
    <div className={styles.logoWrap}>
      <img className={styles.logo} src={url} alt='partner logo' />
    </div>
  );
};
