import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { API } from 'api';
import c from 'classnames';

import { apiBasePath, apiName } from 'assets';
import { Button, THTextarea, Typography } from 'components';

import styles from './SubscriptionDetail.module.scss';
import { BackButton } from 'components/BackButton/BackButton';

type FeedbackValues = {
  feedback: string;
};
//TODO: clean up with useFeature('subscriptions_v2')
export const SubscriptionCancelled = () => {
  const navigate = useNavigate();
  const { subscriptionId } = useParams<{ subscriptionId: string }>();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<FeedbackValues>({ mode: 'onTouched' });

  const submitFeedback = async (formData: { feedback: string }) => {
    try {
      await API.put(
        apiName,
        `${apiBasePath}/subscriptions/${subscriptionId}/cancel/feedback`,
        {
          feedback: formData.feedback,
        },
      );
      navigate('/account');
      toast.success('Thanks for your feedback');
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
  };

  if (!subscriptionId) return <></>;
  return (
    <div className={styles.accountContainer}>
      <div>
        <NavLink to='/account'>
          <BackButton variant='link' />
        </NavLink>
      </div>
      <Typography variant='heading-xl' className={styles.title}>
        Your subscription is now cancelled
      </Typography>
      <div className={c(styles.secondary, 'mt-1 mb-3')}>
        You can still access your results in our platform. If you change your
        mind, you can reactivate your subscription anytime.
      </div>
      <div className={styles.topBorder}>
        We are always improving and your feedback matters.
      </div>
      <form onSubmit={handleSubmit(submitFeedback)}>
        <THTextarea
          rows={3}
          placeholder='Add reasons or feedback'
          maxLength={240}
          errors={errors}
          {...register('feedback', {
            required: {
              value: true,
              message: 'Required.',
            },
          })}
        />
        {errors.feedback?.type === 'required' && (
          <div className={c(styles.formError, 'ps-2')}>
            Please enter your feedback
          </div>
        )}
        <Button
          type='submit'
          width='full'
          loading={isSubmitting}
          disabled={!isValid}
          className='mt-3'
        >
          Submit your feedback
        </Button>
      </form>
    </div>
  );
};

export default SubscriptionCancelled;
