import { useState } from 'react';
import { useSelector } from 'react-redux';

import { colorForTinyAccount } from 'helpers';
import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import TinyAccount from 'types/TinyAccount';

import { Typography } from 'components';
import AddPractitionerModal from '../AddPractitionerModal';

import styles from './Practitioners.module.scss';

const TinyAccountPractitioner = ({
  tinyAccount,
}: {
  tinyAccount: TinyAccount;
}) => {
  const [showModalPractitioner, setShowModalPractitioner] =
    useState<boolean>(false);
  const toggleModalPractitioner = () => setShowModalPractitioner(prev => !prev);
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );

  const practitionerName = !tinyAccount?.practitioner?.mainaccount?.first_name
    ? ''
    : tinyAccount?.practitioner?.mainaccount?.first_name +
      ' ' +
      tinyAccount?.practitioner?.mainaccount?.last_name;

  const addPractitioner = () => {
    setShowModalPractitioner(true);
  };

  return (
    <div className={styles.practitionerCard}>
      <AddPractitionerModal
        tinyAccount={tinyAccount}
        toggle={toggleModalPractitioner}
        showModal={showModalPractitioner}
      />
      <div className={styles.practitionerName}>
        <div
          className={styles.avatarBubble}
          style={{
            backgroundColor: colorForTinyAccount(
              orderedTinyAccounts,
              tinyAccount.id,
            ),
          }}
        >
          <Typography variant='heading-xl'>
            {tinyAccount.first_name.substr(0, 1)}
          </Typography>
        </div>
        <Typography variant='heading-s'>{`${tinyAccount.first_name}'s practitioner`}</Typography>
      </div>
      {!tinyAccount?.practitioner ? (
        <div className={styles.actionContainer}>
          <div className={styles.action} onClick={addPractitioner}>
            Add
          </div>
        </div>
      ) : (
        <div className='flex flex-row align-items-center justify-content-between w-100'>
          <div className='flex flex-column gap-1'>
            <Typography variant='label' weight='bold'>
              {practitionerName}
            </Typography>
            <Typography variant='label'>
              {tinyAccount?.practitioner.organization}
            </Typography>
          </div>
          <div className={styles.action} onClick={addPractitioner}>
            Edit
          </div>
        </div>
      )}
    </div>
  );
};

export const Practitioners = () => {
  const tinyAccounts = useSelector(getTinyAccountsOrderedByLatestKitResults);

  if (!tinyAccounts?.length)
    return (
      <div className='mt-2'>
        <Typography variant='label' className='dark-grey'>
          You need to create a profile first.
        </Typography>
      </div>
    );
  return (
    <div className={styles.practitionersGrid}>
      {tinyAccounts.map(tinyAccount => (
        <TinyAccountPractitioner tinyAccount={tinyAccount} />
      ))}
    </div>
  );
};
