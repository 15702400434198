import { useMemo } from 'react';
import { format } from 'date-fns';
import c from 'classnames';

import useSubscriptions from 'services/general/subscriptionsList';
import { capitalizeFirstLetter } from 'helpers';
import { Subscription, SubscriptionStatus } from 'types/Subscription';

import { TextLink } from '@repo/ui';
import { Card, ContainerSizes, PageContainer, Typography } from 'components';
import testPackage from 'assets/images/subscription.jpg';

import styles from './Subscriptions.module.scss';

export const StatusPill = ({ status }: { status: SubscriptionStatus }) => {
  return (
    <div className={c(styles.pill, styles[status])}>
      {capitalizeFirstLetter(status)}
    </div>
  );
};

const SubscriptionCard = ({ subscription }: { subscription: Subscription }) => {
  const numKits =
    (subscription.shopify_product?.num_kits_gut ?? 0) +
    (subscription.shopify_product?.num_kits_vaginal ?? 0) +
    (subscription.shopify_product?.num_kits_stool_chemistry ?? 0);
  const numCredits = subscription.shopify_product?.consult_credits ?? 0;

  return (
    <div className={styles.subscriptionCard}>
      <div
        className={c(
          styles.subscriptionImage,
          subscription.status !== SubscriptionStatus.ACTIVE &&
            styles.deactivated,
        )}
      >
        <img src={testPackage} alt={`Test package`} />
      </div>
      <div className={styles.subscriptionContent}>
        <div className={styles.subscriptionContentInfo}>
          <Typography
            variant='heading-m'
            className={
              subscription.status !== SubscriptionStatus.ACTIVE
                ? 'dark-grey'
                : ''
            }
          >
            {subscription.shopify_product_name}
          </Typography>
          <Typography variant='label' className='dark-grey'>
            {`${numKits} test kits${numCredits ? `, ${numCredits} coaching session` : ''}`}
          </Typography>
          <Typography variant='label' className='dark-grey'>
            {`Renews ${format(
              new Date(subscription.date_next_charge_scheduled),
              'MM/dd/yy',
            )}`}
          </Typography>
          <div className='flex align-items-center gap-1'>
            <Typography variant='label' className='dark-grey'>
              {'$' +
                (subscription?.next_charge?.total_price
                  ? subscription.next_charge.total_price
                  : subscription.price)}
            </Typography>
            {!!subscription?.next_charge?.total_price &&
              subscription?.next_charge?.total_price !==
                parseFloat(subscription.price) && (
                <Typography variant='label' className='dark-grey line-through'>
                  {'$' + subscription.price}
                </Typography>
              )}
          </div>
        </div>
        <div className='flex align-items-center gap-4 flex-1'>
          <StatusPill status={subscription.status} />
          {subscription.status !== SubscriptionStatus.CANCELLED && (
            <TextLink
              to={`/account/subscriptions/${subscription.id}/cancellation`}
              label='Cancel'
              iconNameLeft='trash'
              color='red'
              className='ml-auto'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const Subscriptions = () => {
  const { data: subscriptionsList } = useSubscriptions();

  const activeSubscriptions = useMemo(
    () =>
      subscriptionsList?.filter(
        subscription => subscription.status === 'active',
      ) ?? [],
    [subscriptionsList],
  );
  const inactiveSubscriptions = useMemo(
    () =>
      subscriptionsList?.filter(
        subscription => subscription.status !== 'active',
      ) ?? [],
    [subscriptionsList],
  );

  if (!subscriptionsList?.length) return <></>;
  return (
    <PageContainer size={ContainerSizes.MD} className={styles.pageContainer}>
      <div className='mt-3'>
        <TextLink
          to='/account'
          label='Back to Account'
          iconNameLeft='chevronBack'
          size='body-s'
        />
      </div>
      <Card variant='wrapper' className={styles.cardPadding}>
        <Typography variant='heading-l'>Your Active Subscriptions</Typography>
        {!activeSubscriptions.length && (
          <Typography variant='body-s'>No active subscriptions</Typography>
        )}
        {activeSubscriptions.map(subscription => (
          <SubscriptionCard subscription={subscription} key={subscription.id} />
        ))}
      </Card>
      <Card variant='wrapper' className={styles.cardPadding}>
        <Typography variant='heading-l'>Your inactive Subscriptions</Typography>
        {!inactiveSubscriptions.length && (
          <Typography variant='body-s'>No inactive subscriptions</Typography>
        )}
        {inactiveSubscriptions.map(subscription => (
          <SubscriptionCard subscription={subscription} key={subscription.id} />
        ))}
      </Card>
      <div className={styles.contact}>
        <Typography variant='heading-m'>Contact us</Typography>
        <Typography variant='body-s'>
          For help with subscription management or questions, please email
          hello@tinyhealth.com
        </Typography>
      </div>
    </PageContainer>
  );
};
