import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { parseDate, sortKitsByLatestSampleDate } from 'helpers';
import Kit from 'types/Kit';
import MainAccount, {
  MembershipPlan,
  MembershipType,
  Practitioner,
  Program,
} from 'types/MainAccount';
import TinyAccount from 'types/TinyAccount';
import { getKits } from './shared';
import { RootState } from './store';
import { getImpersonateId, getUsername, impersonate, signOut } from './user';
import { Consult } from 'types/Consult';
import { differenceInCalendarDays } from 'date-fns';

interface AccountState {
  mainAccountId: string | null;
  email?: string;
  firstName?: string;
  lastName?: string;
  membershipPlan?: MembershipPlan | null; // null, 'tiny_plus', 'tiny_plus_programs', 'tiny_plus_proactive' or 'tiny_plus_practitioners'
  membershipType?: MembershipType | null; // 'free', 'premium', or 'vip' (vip are premiums we pay for)
  practitionerId?: string | null;
  phoneNumber?: string | null;
  referralCode?: string | null;
  referralCredit?: number;
  consultCredits?: number;
  consultCreditsCount?: number;
  dateMembershipDowngraded?: string | null;
  dateMembershipUpgraded?: string | null;
  dateNextSuggestedConsult?: string | null;
  tinyAccounts?: TinyAccount[];
  programs?: Program[];
  practitioner?: Practitioner | null;
}

export const initialState: AccountState = {
  mainAccountId: null,
  membershipPlan: null, // null, 'tiny_plus', 'tiny_plus_programs', 'tiny_plus_proactive' or 'tiny_plus_practitioners'
  membershipType: null, // 'free', 'premium', or 'vip' (vip are premiums we pay for)
  practitionerId: null,
  phoneNumber: null,
  referralCode: null,
  dateMembershipDowngraded: null,
  dateMembershipUpgraded: null,
  dateNextSuggestedConsult: null,
  tinyAccounts: [],
  programs: [],
  practitioner: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setMainAccount(state, action: PayloadAction<MainAccount>) {
      state.mainAccountId = action.payload.id;
      state.practitionerId = action.payload.practitioner_id;
      state.email = action.payload.email;
      state.firstName = action.payload.first_name;
      state.lastName = action.payload.last_name;
      state.membershipPlan = action.payload.membership_plan;
      state.membershipType = action.payload.membership_type;
      state.programs = action.payload.programs;
      state.practitioner = action.payload.practitioner;
      state.phoneNumber = action.payload.phone_number;
      state.dateMembershipDowngraded =
        action.payload.date_membership_downgraded;
      state.dateMembershipUpgraded = action.payload.date_membership_upgraded;
      state.dateNextSuggestedConsult =
        action.payload.next_suggested_consult_date;
      state.referralCode = action.payload.referral_code;
      state.referralCredit = action.payload.referral_credit;
      state.consultCreditsCount = action.payload.consult_credits_count;
    },
    setTinyAccounts(state, action: PayloadAction<TinyAccount[]>) {
      state.tinyAccounts = action.payload;
    },
    setConsultCreditsCount(state, action: PayloadAction<number>) {
      state.consultCreditsCount = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(signOut, (state, action) => {
        return initialState;
      })
      .addCase(impersonate, (state, action) => {
        return initialState;
      }),
});

// Selectors
const getAccountState = (state: RootState) => state.account;
export const getTinyAccounts = createSelector(
  getAccountState,
  state => state.tinyAccounts,
);
export const getMainAccountId = createSelector(
  [getAccountState, getImpersonateId, getUsername],
  (account, impersonateId, username) =>
    (impersonateId ? impersonateId : username) || account.mainAccountId,
);
export const getAccountEmail = createSelector(
  getAccountState,
  state => state.email,
);
export const getFirstName = createSelector(
  getAccountState,
  state => state.firstName,
);
export const getLastName = createSelector(
  getAccountState,
  state => state.lastName,
);
export const getFullName = createSelector(
  [getFirstName, getLastName],
  (firstName, lastName) => {
    if (!firstName) return lastName;
    if (!lastName) return firstName;
    return firstName + ' ' + lastName;
  },
);
export const getIsTinyPlus = createSelector(
  getAccountState,
  state => state.membershipPlan === MembershipPlan.TINY_PLUS,
);
export const getIsTinyPlusPrograms = createSelector(
  getAccountState,
  state => state.membershipPlan === MembershipPlan.TINY_PLUS_PROGRAMS,
);
export const getIsTinyPlusProactive = createSelector(
  getAccountState,
  state => state.membershipPlan === MembershipPlan.TINY_PLUS_PROACTIVE,
);
export const getIsAnyTinyPlus = createSelector(
  getAccountState,
  state =>
    !!state.membershipPlan &&
    [
      MembershipPlan.TINY_PLUS,
      MembershipPlan.TINY_PLUS_PROGRAMS,
      MembershipPlan.TINY_PLUS_PROACTIVE,
    ].includes(state.membershipPlan),
);
export const getIsInProgram = createSelector(
  getAccountState,
  state => !!state.programs?.length,
);
export const getProgramConsultLink = createSelector(getAccountState, state => {
  if (!state.programs?.length) return null;
  return state.programs[0]?.consults_link;
});
export const getParsedMembershipDate = createSelector(getAccountState, state =>
  state?.dateMembershipUpgraded
    ? parseDate(state.dateMembershipUpgraded)
    : null,
);
export const getParsedNextConsultDate = createSelector(
  getAccountState,
  state =>
    state?.dateNextSuggestedConsult
      ? parseDate(state.dateNextSuggestedConsult)
      : null,
);
export const getHasAlreadyScheduledCloseToDate = (
  upcomingConsults?: Consult[],
) =>
  createSelector(getParsedNextConsultDate, nextConsultDate => {
    if (!upcomingConsults || !nextConsultDate) return false;
    const nearbyConsult = upcomingConsults.find(consult => {
      const consultDate = new Date(consult.scheduled_dt * 1000);
      const daysDifference = differenceInCalendarDays(
        nextConsultDate,
        consultDate,
      );
      return daysDifference < 15 && daysDifference > -15;
    });
    return !!nearbyConsult;
  });
export const getPractitionerId = createSelector(
  getAccountState,
  state => state.practitionerId,
);
export const getReferralCode = createSelector(
  getAccountState,
  state => state.referralCode,
);
export const getReferralCredit = createSelector(getAccountState, state =>
  state.referralCredit ? state.referralCredit / 100 : 0,
);
export const getConsultCreditsCount = createSelector(getAccountState, state =>
  state.consultCreditsCount ? state.consultCreditsCount : 0,
);
export const getHasPartnerKit = createSelector(getKits, kits =>
  kits.some(kit => kit.partner),
);
const createTinyAccountsComparitor = (kits: Kit[]) => {
  return (a: TinyAccount, b: TinyAccount) => {
    if (!kits?.length) {
      return 0;
    }
    const aKits = sortKitsByLatestSampleDate(
      [...kits].filter(kit => kit.tinyaccount_id === a.id),
    );
    const bKits = sortKitsByLatestSampleDate(
      [...kits].filter(kit => kit.tinyaccount_id === b.id),
    );
    if (!bKits.length) return -1;
    if (!aKits.length) return 1;
    if (!bKits[0]?.sample_date) return 0;
    if (!aKits[0]?.sample_date) return 0;
    return (
      parseDate(bKits[0].sample_date).getTime() -
      parseDate(aKits[0].sample_date).getTime()
    );
  };
};

export const getTinyAccountsOrderedByLatestKitResults = createSelector(
  [getTinyAccounts, getKits],
  (tinyAccounts, kits) => {
    if (!tinyAccounts?.length) return [];
    return [...tinyAccounts]?.sort(createTinyAccountsComparitor(kits)) ?? [];
  },
);
export const getTinyAccountsWithTheseKits = (kitIds?: string[]) =>
  createSelector(
    [getTinyAccountsOrderedByLatestKitResults, getKits],
    (tinyAccounts, kits) => {
      const kitsThatMatter = kits.filter(kit => kitIds?.includes(kit.id));
      const tinyAccountsIdsThatMatter = kitsThatMatter.map(
        kit => kit.tinyaccount_id,
      );
      return tinyAccounts.filter(ta =>
        tinyAccountsIdsThatMatter.includes(ta.id),
      );
    },
  );

export const getTinyAccountById = (id?: string | null) =>
  createSelector(getTinyAccounts, tinyAccounts => {
    if (!tinyAccounts?.length) return null;
    return id ? tinyAccounts.find(tinyAccount => tinyAccount.id === id) : null;
  });
export const getBirthMotherForTinyAccountId = (id?: string | null) =>
  createSelector(getTinyAccounts, tinyAccounts => {
    if (!tinyAccounts?.length || !id) return null;
    const birthMotherId = tinyAccounts.find(
      tinyAccount => tinyAccount.id === id,
    )?.birth_mother_id;
    if (birthMotherId) {
      return (
        tinyAccounts.find(tinyAccount => tinyAccount.id === birthMotherId) ??
        null
      );
    } else return null;
  });
export const { setMainAccount, setTinyAccounts, setConsultCreditsCount } =
  accountSlice.actions;
export default accountSlice.reducer;
