import { API } from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiBasePath, apiName } from 'assets';

type DiscountResponse = {
  result: object;
};

export const SubscriptionCancelOfferDiscountCodes = {
  CANCEL_50: 'CANCEL_50',
  CANCEL_100: 'CANCEL_100',
};

const acceptDiscount = async ({
  subscriptionId,
  discount,
}: {
  subscriptionId: string;
  discount: number;
}) => {
  const discount_code =
    discount === 100
      ? SubscriptionCancelOfferDiscountCodes.CANCEL_100
      : SubscriptionCancelOfferDiscountCodes.CANCEL_50;
  const response: DiscountResponse = await API.put(
    apiName,
    `${apiBasePath}/subscriptions/${subscriptionId}/discount`,
    {
      discount_code,
    },
  );
  return response?.result;
};

const useSubscriptionDiscount = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({
      discount,
      subscriptionId,
    }: {
      discount: number;
      subscriptionId: string;
    }) => {
      if (![50, 100].includes(discount)) {
        throw new Error('Invalid discount value.');
      }
      return acceptDiscount({ subscriptionId, discount });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: query =>
          query.queryKey.includes('subscription') ||
          query.queryKey.includes('subscriptionsList'),
      });
    },
  });
  return mutation;
};
export default useSubscriptionDiscount;
