import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { ConditionMetric } from 'types/Conditions';

const getConditionMetrics = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/conditions/metrics/${kitId}`,
    {},
  );
  return response.result as ConditionMetric[];
};

export const getConditionMetricsQuery = (kitId?: string) => ({
  queryKey: [apiName, `getConditionMetrics`, kitId],
  queryFn: () => (kitId ? getConditionMetrics(kitId) : []),
});

const useConditionsMetrics = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const conditionMetricsQuery = useSuspenseQuery<ConditionMetric[]>(
    getConditionMetricsQuery(currentKitId),
  );

  return conditionMetricsQuery;
};

export default useConditionsMetrics;
