import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { NewCondition } from 'types/Conditions';

import { Card, Typography } from 'components';

import styles from './ConditionMetricsAssociated.module.scss';
import useConditionMetrics from 'services/conditions/useConditionMetrics';

import { ConditionMetricRow } from './ConditionMetricRow';
import { ConditionMetricPill } from './ConditionMetricPill';
import { useMemo } from 'react';
import useActionPlanData from 'services/actionPlan/actionPlanData';

export const ConditionMetricsAssociated = ({
  condition,
}: {
  condition: NewCondition;
}) => {
  const { currentTinyAccount, currentKitId } = useCurrentKitAndTinyAccount();

  const { conditionMetricsQuery: conditionMetrics, groupConditionMetricItems } =
    useConditionMetrics();
  const { data: actionPlanData } = useActionPlanData({ kitId: currentKitId });

  const associatedMetrics = useMemo(() => {
    return groupConditionMetricItems(
      conditionMetrics.data,
      [],
      actionPlanData ?? [],
    );
  }, []);

  const colorOrder = ['red', 'orange', 'grey', 'green'];

  const foundMetric = associatedMetrics.find(
    metric => metric.condition === condition.condition,
  );

  const sortedConditionMetrics = foundMetric
    ? {
        ...foundMetric,
        metrics: foundMetric.metrics.sort((a, b) => {
          const indexA = colorOrder.indexOf(a.evaluation_color);
          const indexB = colorOrder.indexOf(b.evaluation_color);
          return indexA - indexB;
        }),
      }
    : null;

  const sum =
    (sortedConditionMetrics?.good ?? 0) +
    (sortedConditionMetrics?.okay ?? 0) +
    (sortedConditionMetrics?.improve ?? 0) +
    (sortedConditionMetrics?.support ?? 0);

  if (!currentKitId || !currentTinyAccount) return null;

  return (
    <Card className={styles.cardMetricsContainer}>
      <div className={styles.header}>
        <Typography variant='body-s' className={styles.topText}>
          Based on your sample
        </Typography>
        <Typography variant='heading-l'>
          {condition.condition_display_name}
        </Typography>
        <ConditionMetricPill
          good={sortedConditionMetrics?.good ?? 0}
          okay={sortedConditionMetrics?.okay ?? 0}
          improve={sortedConditionMetrics?.improve ?? 0}
          support={sortedConditionMetrics?.support ?? 0}
        />
      </div>
      <div className={styles.content}>
        <Typography variant='heading-m'>
          {sum} metrics associated with {condition.condition_display_name}
        </Typography>
      </div>
      {sortedConditionMetrics &&
        sortedConditionMetrics.metrics.map((metric, index) => (
          <ConditionMetricRow key={index} metric={metric} row={index} />
        ))}
    </Card>
  );
};
