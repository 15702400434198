import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import usePartnerActivateKit from 'services/kits/usePartnerActivateKit';
import { EMAIL_VALIDATION_PATTERN } from 'assets';
import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  THField,
  Typography,
} from 'components';
import { InformationScreenProps } from '../InformationStep';

type EmailFormValues = {
  email: string;
};

export const EmailScreen = ({
  setActivationState,
  activationState,
  step,
  setCurrentActivationStep,
}: InformationScreenProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EmailFormValues>({
    mode: 'onTouched',
  });
  const partnerActivateKit = usePartnerActivateKit();

  const emailFormField = register('email', {
    required: { value: true, message: 'Please enter your email' },
    pattern: EMAIL_VALIDATION_PATTERN,
  });

  const onSubmitEmail = async (formData: EmailFormValues) => {
    try {
      const { kit: responseKit, tinyaccount: responseTinyAccount } =
        await partnerActivateKit.mutateAsync({
          kit: activationState.kit,
          tinyAccount: {
            ...activationState.tinyAccount,
            email: formData.email,
          },
        });

      const updatedKit = {
        ...activationState.kit,
        tinyaccount_id: responseKit.tinyaccount_id,
      };
      const updatedTinyAccount = {
        ...activationState.tinyAccount,
        id: responseTinyAccount.id,
        email: formData.email,
      };
      setActivationState({
        kit: updatedKit,
        tinyAccount: updatedTinyAccount,
      });

      if (typeof step === 'number' && !!setCurrentActivationStep) {
        setCurrentActivationStep(step + 1);
      }
    } catch (error) {
      toast.error('Failed to activate kit. Please try again.');
    }
  };

  return (
    <form
      className='flex flex-column gap-2'
      onSubmit={handleSubmit(onSubmitEmail)}
    >
      <Typography variant='heading-l'>What is your email?</Typography>
      <THField
        type='email'
        label='Email'
        icon='mail'
        errors={errors}
        {...emailFormField}
      />
      <DesignButton
        label='Continue'
        type='submit'
        loading={partnerActivateKit.isPending}
        disabled={!isValid}
        size={ButtonSizes.M}
        color={ButtonColors.PURPLE}
        width={'full'}
        className='mt-1'
      />
    </form>
  );
};
