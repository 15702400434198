import { useLocation } from 'react-router-dom';

import { TextLink, Typography } from 'components';

import styles from './ConditionsMetricsSummaryCard.module.scss';

export const ConditionsMetricsSummaryCard = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.summaryCardWrapper}>
      <div className={styles.mainContent}>
        <div className={styles.newPill}>
          <Typography variant='label'>NEW</Typography>
        </div>
        <Typography variant={'heading-l'}>
          Conditions & the microbiome
        </Typography>
        <Typography variant={'body-s'} serif className={styles.textGrey}>
          See metrics related to common conditions.
        </Typography>
      </div>
      <TextLink
        label={'Learn more'}
        iconNameRight='chevronForward'
        to={`${pathname}/conditions/metrics`}
        size='label'
      />
    </div>
  );
};
