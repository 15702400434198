import { useSelector } from 'react-redux';

import { getTinyAccountsOrderedByLatestKitResults } from 'store/account';
import Dropdown, { SelectOption } from 'components/Elements/Dropdown/Dropdown';

import styles from './TinyAccountSelect.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getKitById, getKitsForTinyAccountId } from 'store/kits';
import { getKits } from 'store/shared';
import { sortKitsByResultsViewableOrLatest } from 'helpers';
import { Icon, Typography } from '@repo/ui';

export const TinyAccountSelect = () => {
  const orderedTinyAccounts = useSelector(
    getTinyAccountsOrderedByLatestKitResults,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { kitId: currentKitId } = useParams<{ kitId: string }>();
  const currentKit = useSelector(getKitById(currentKitId));
  const currentTinyAccountId = currentKit?.tinyaccount_id;
  const kits = useSelector(getKits);

  function setCurrentTinyAccount(tinyAccountId: string) {
    const kitsForAccount = kits.filter(
      kit => kit.tinyaccount_id === tinyAccountId,
    );
    const latestKitWithResults =
      sortKitsByResultsViewableOrLatest(kitsForAccount)[0];
    const newPath = pathname.replace(
      `/${currentKitId}`,
      `/${latestKitWithResults?.id ?? ''}`,
    );
    if (kitsForAccount.length < 1) {
      navigate('/activate?tinyaccount_id=' + tinyAccountId, {
        state: { from: pathname },
      });
    } else if (currentTinyAccountId !== tinyAccountId) {
      navigate(newPath, { replace: true });
    }
  }

  if (
    !orderedTinyAccounts ||
    orderedTinyAccounts.length < 1 ||
    !currentTinyAccountId
  )
    return null;

  const tinyAccountSelectOptions = orderedTinyAccounts.map(tinyAccount => {
    return {
      label: tinyAccount.first_name,
      value: tinyAccount.id,
    };
  });

  return (
    <Dropdown
      options={tinyAccountSelectOptions}
      value={currentTinyAccountId}
      onChange={setCurrentTinyAccount}
      CustomOption={({ innerProps, data, ...props }) => (
        <TinyAccountSelectOption {...innerProps} data={data} {...props} />
      )}
      controlIconString='userSingle'
      controlLabel='Family member'
      controlSubLabel='Change'
      hideControlSubLabelMobile
      // menuIsOpen
    />
  );
};

const TinyAccountSelectOption = ({
  label,
  data,
}: {
  label: string;
  data: SelectOption;
}) => {
  const hasKit = useSelector(getKitsForTinyAccountId(data.value)).length > 0;
  return (
    <div className={styles.selectOption}>
      <div className={styles.nameGroup}>
        <div className={styles.nameBubble}>{label[0]}</div>
        <Typography variant='body-s' className={styles.name}>
          {label}
        </Typography>
      </div>
      {!hasKit && (
        <>
          <Typography variant='label' className={styles.kitsInfo}>
            Register kit
          </Typography>
          <Icon name='chevronForward' size='s' />
        </>
      )}
    </div>
  );
};
