import { Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import c from 'classnames';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';

import { ConditionMetric, NewCondition } from 'types/Conditions';
import { FeedbackPageType } from 'types/Feedback';

import {
  Spinfinity,
  PageContainer,
  ContainerSizes,
  FeedbackWidget,
  Card,
  FAQ,
  Markdown,
} from 'components';

import { ConditionMetricsAssociated } from './ConditionMetricsAssociated';

import { ConditionMetricActions } from './ConditionMetricActions';
import styles from './ConditionMetricsDetailView.module.scss';
import useConditionMetrics from 'services/conditions/useConditionMetrics';
import useActionPlanData from 'services/actionPlan/actionPlanData';

export const ConditionMetricsDetailView = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const { condition } = useParams<{ condition: string }>();
  if (!condition) throw new Error('No condition provided');

  const {
    conditionActionPlanQuery: conditionActionPlan,
    metricConditionsQuery: metricConditions,
  } = useConditionMetrics();

  const { data: actionPlanData } = useActionPlanData({ kitId: currentKitId });

  const conditionActionPlanData = conditionActionPlan.data;
  const metricConditionsData = metricConditions.data;

  const currentCondition: NewCondition | null = useMemo(
    () =>
      metricConditionsData?.find(
        (cond: ConditionMetric) => cond.condition === condition,
      ) || null,
    [condition, metricConditionsData],
  );

  const matchedActions = useMemo(() => {
    if (!actionPlanData || !conditionActionPlanData) return [];
    return conditionActionPlanData
      .map(action => {
        const matchedAction = actionPlanData.find(
          actionPlanItem =>
            actionPlanItem.id === +action.action_plan_recommendations_id,
        );
        return matchedAction
          ? { ...action, display_title: matchedAction.display_title }
          : null;
      })
      .filter(action => {
        return condition?.includes(action?.tags || '');
      })
      .filter(action => action !== null);
  }, [actionPlanData, conditionActionPlanData]);

  if (!currentKitId || !currentCondition) return <Spinfinity />;

  return (
    <PageContainer size={ContainerSizes.LG}>
      <div className={styles.mainSectionContainer}>
        <div className={styles.wideSection}>
          <div className={styles.contentContainer}>
            <Suspense fallback={<Spinfinity />}>
              <ConditionMetricsAssociated condition={currentCondition} />
            </Suspense>
            {!!currentCondition?.about && (
              <Card>
                <div className={styles.secondaryText}>
                  <Markdown>{currentCondition.about}</Markdown>
                </div>
              </Card>
            )}
            <ConditionMetricActions
              condition={currentCondition}
              actions={matchedActions}
              className='hide-on-desktop'
            />
            {currentCondition?.faq && (
              <Card
                title='Frequently Asked Questions'
                className={styles.faqCard}
              >
                <FAQ faq={currentCondition.faq} />
              </Card>
            )}
            <FeedbackWidget
              kitId={currentKitId}
              pageType={FeedbackPageType.CONDITION}
              pageId={currentCondition?.id}
            />
          </div>
        </div>
        <div className={c(styles.slimSection, 'hide-on-mobile')}>
          <ConditionMetricActions
            condition={currentCondition}
            actions={matchedActions}
          />
        </div>
      </div>
    </PageContainer>
  );
};
