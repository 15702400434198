import { useEffect, useMemo, useRef } from 'react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toBlob } from 'html-to-image';

import { getFirstParagraph, stringifyDaysOld } from 'helpers';
import useFeature from 'services/features/useFeature';
import { getSubMetricDetailQuery } from 'services/insights/useSubMetricDetail';
import { getTaxaOverTimeQuery } from 'services/insights/useTaxaOverTime';
import { getActionPlanItemsQuery } from 'services/actionPlan/actionPlanData';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { InsightMetric } from 'types/Insights';
import { FeedbackPageType } from 'types/Feedback';

import {
  ContainerSizes,
  PageContainer,
  FeedbackWidget,
  Typography,
  AssociationsList,
  FAQ,
  DesignButton,
  Card,
  TextLink,
  Markdown,
  PageHeader,
  EvidenceRating,
} from 'components';
import { ButtonColors, ButtonVariants, Icon } from '@repo/ui';
import MaturationIndexGraph from 'components/MaturationIndexGraph/MaturationIndexGraph';
import MaturationIndexShareImage from 'components/MaturationIndexGraph/MaturationIndexShareImage';
import { OffScreenContainer } from 'views/Results/Summary/SummaryCards/MicrobiomeScoreSummaryCard/MicrobiomeScoreSummaryCard';
import { ActionPlanGroup } from 'views/ActionPlan/ActionPlanGroup/ActionPlanGroup';
import { EvaluationPillFromColor } from '../SubMetricGraphs/SubMetricGraphs';
import {
  getActionsForSubMetric,
  InsightBreadcrumbs,
  RelatedActions,
} from './SubMetricDetail';
import { SubMetricData } from '../SubMetricData/SubMetricData';

import styles from './MaturationIndexDetail.module.scss';

type SubMetricDataProps = {
  subMetric: InsightMetric;
  relatedActionsQty: number;
  refAP: React.RefObject<HTMLElement>;
};
const MaturationIndexData = ({
  subMetric,
  relatedActionsQty,
  refAP,
}: SubMetricDataProps) => {
  const { currentKit, currentKitId, currentTinyAccount } =
    useCurrentKitAndTinyAccount();
  const imageRef = useRef<HTMLDivElement>(null);

  const source = useMemo(() => {
    return getFirstParagraph(subMetric?.about);
  }, [subMetric?.about]);

  const associations = useMemo(
    () => subMetric?.gut_vaginal_health?.associations_list || [],
    [subMetric?.gut_vaginal_health?.associations_list],
  );

  const onShareClick = async () => {
    if (!imageRef.current) return;
    const newFile = await toBlob(imageRef.current as HTMLElement, {
      skipFonts: true,
    });

    if (!newFile) return;
    const data = {
      files: [
        new File([newFile], 'tinyhealth_maturation_index.png', {
          type: 'image/png',
          lastModified: Date.now(),
        }),
      ],
    };
    try {
      if (!navigator?.canShare?.(data)) {
        navigator.clipboard.write([
          new ClipboardItem({
            'image/png': newFile,
          }),
        ]);
        toast.success('Image copied to clipboard');
      } else {
        await navigator.share(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (!subMetric || !currentKit) return <></>;
  return (
    <>
      <div className='flex flex-column gap-4'>
        {!!relatedActionsQty && (
          <Card className={styles.actionsReminder}>
            <div className='flex gap-2'>
              <div className={styles.iconWrapper}>
                <Icon name='flashFilled' size='m' />
              </div>
              <div className='flex flex-column gap-1'>
                <Typography variant='heading-s'>{`${relatedActionsQty} actions related to this index`}</Typography>
                <DesignButton
                  label='See actions'
                  iconNameRight='chevronForward'
                  color={ButtonColors.WHITE}
                  variant={ButtonVariants.TEXT}
                  onClick={async () => {
                    if (refAP.current) {
                      window.scrollTo({
                        top: refAP.current.offsetTop - 10,
                        behavior: 'smooth',
                      });
                    }
                  }}
                />
              </div>
            </div>
          </Card>
        )}

        <Card>
          <div className='flex justify-between align-items-center mb-3'>
            <Typography variant={'heading-s'}>Maturation index</Typography>
            <div>
              <DesignButton
                onClick={() => onShareClick()}
                label='Share'
                variant={ButtonVariants.TEXT}
                color={ButtonColors.PURPLE}
                iconNameRight='share'
              />
            </div>
          </div>
          <div className='flex flex-column gap-2'>
            <Typography variant={'heading-xl'} className='mb-4'>
              {`${currentTinyAccount?.first_name}’s microbiome age & development`}
            </Typography>

            <Typography variant={'heading-m'} className='dark-grey'>
              {`At ${stringifyDaysOld(currentKit.age_at_sampling ?? 0)}, ${currentTinyAccount?.first_name}’s gut looks like that of a`}
            </Typography>
            <div className='flex align-items-center gap-2'>
              <Typography
                variant={'heading-2xl'}
                className={subMetric.evaluation_color}
                serif
              >
                {stringifyDaysOld(subMetric.user_value, true)}
              </Typography>
              <div>
                <EvaluationPillFromColor
                  evaluation_color={subMetric.evaluation_color}
                  showIconOnly
                />
              </div>
            </div>
          </div>
          <MaturationIndexGraph kit={currentKit} subMetric={subMetric} />
          <div className='flex align-items-center gap-1 hide-on-mobile mt-2'>
            <EvaluationPillFromColor evaluation_color='red' />
            <EvaluationPillFromColor evaluation_color='orange' />
            <EvaluationPillFromColor evaluation_color='grey' />
            <EvaluationPillFromColor evaluation_color='green' />
          </div>
          {subMetric?.detected_text && (
            <div className='mt-4'>
              <Typography variant='heading-m'>
                <Markdown className='default-font'>
                  {subMetric.detected_text}
                </Markdown>
              </Typography>
            </div>
          )}

          {!!associations?.length && (
            <div>
              <Typography variant='heading-m'>
                Out of range maturation could be linked to:
              </Typography>
              <AssociationsList associations={associations} />
            </div>
          )}
          <TextLink
            to={`/action-plan/${currentKitId}`}
            label='See your Action Plan'
            iconNameRight='chevronForward'
            className='mt-2'
          />
        </Card>

        <Card>
          <div className='flex flex-column gap-3'>
            <Typography variant='heading-m'>About</Typography>
            {subMetric?.evidence && (
              <EvidenceRating evidenceRating={subMetric.evidence} />
            )}
            <div className={styles.detailAbout}>
              <Markdown>{source ?? ''}</Markdown>
            </div>
          </div>
        </Card>

        {!!subMetric?.faq && (
          <Card>
            <div>
              <Typography variant='heading-l'>
                Frequently asked questions
              </Typography>
              <FAQ faq={subMetric.faq} hideLastDivider />
            </div>
          </Card>
        )}

        {!!subMetric?.gut_vaginal_health?.id && (
          <div className='hide-on-mobile'>
            <FeedbackWidget
              kitId={currentKit.id}
              pageType={FeedbackPageType.INSIGHT}
              pageId={subMetric.gut_vaginal_health.id}
            />
          </div>
        )}
      </div>
      <OffScreenContainer>
        {imageRef ? (
          <MaturationIndexShareImage
            name={currentTinyAccount?.first_name ?? ''}
            kit={currentKit}
            subMetric={subMetric}
            ref={imageRef}
          />
        ) : null}
      </OffScreenContainer>
    </>
  );
};

export const MaturationIndexDetail = () => {
  const { kitId, subMetricId } = useParams<{
    kitId: string;
    subMetricId: string;
  }>();
  const { state } = useLocation();
  const [{ data: subMetric }, { data: allActions }] = useSuspenseQueries({
    queries: [
      getSubMetricDetailQuery(kitId, subMetricId),
      getActionPlanItemsQuery(kitId),
      getTaxaOverTimeQuery(kitId, parseInt(subMetricId ?? '')),
    ],
  });
  const { isFeatureEnabled } = useFeature('ap_group_by_outcome');
  const { isFeatureEnabled: showNewMatIndex } = useFeature(
    'maturation_index_v2',
  );
  const startAPRef = useRef(null);
  const actionItems = getActionsForSubMetric(allActions, subMetricId);

  useEffect(() => {
    if (state?.scrollTo === 'actions') {
      if (startAPRef.current) {
        const offset = (startAPRef.current as HTMLElement).offsetTop - 10;
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  }, [state]);

  return (
    <PageContainer size={ContainerSizes.LG}>
      {!showNewMatIndex && (
        <>
          <InsightBreadcrumbs subMetric={subMetric} />
          <PageHeader
            title={subMetric?.name}
            beta={subMetric?.gut_vaginal_health?.beta}
          />
        </>
      )}
      <div className={styles.sectionsContainer}>
        <main>
          {showNewMatIndex ? (
            <MaturationIndexData
              subMetric={subMetric}
              relatedActionsQty={actionItems?.length ?? 0}
              refAP={startAPRef}
            />
          ) : (
            <SubMetricData
              metric={subMetric}
              relatedActionsQty={actionItems?.length ?? 0}
              refAP={startAPRef}
            />
          )}
        </main>
        <section id='actions' ref={startAPRef}>
          {isFeatureEnabled ? (
            <RelatedActions actions={actionItems} />
          ) : (
            <ActionPlanGroup actionPlanItems={actionItems} withNavigation />
          )}

          {!!kitId && !!subMetric?.gut_vaginal_health?.id && (
            <div className='mt-4 hide-on-desktop'>
              <FeedbackWidget
                kitId={kitId}
                pageType={FeedbackPageType.INSIGHT}
                pageId={subMetric.gut_vaginal_health.id}
              />
            </div>
          )}
        </section>
      </div>
    </PageContainer>
  );
};
