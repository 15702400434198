import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSuspenseQueries } from '@tanstack/react-query';

import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { getConditionMetricsQuery } from 'services/conditions/conditionsMetrics';
import { getConditionActionPlanQuery } from 'services/conditions/conditionsActionPlan';
import { ConditionMetric, ConditionActionPlan } from 'types/Conditions';
import { KitType } from 'types/Kit';

import ConditionMetricCard from './ConditionMetricCard';

import conditionsBaby from 'assets/images/conditions-baby.png';
import conditionsToddler from 'assets/images/conditions-toddler.png';
import conditionsChild from 'assets/images/conditions-child.png';
import conditionsAdult from 'assets/images/conditions-adult.png';
import conditionsVaginal from 'assets/images/conditions-vaginal.png';

import { getHasModalOpened, setModalOpened } from 'store/modal';
import { ConditionsInfoModal } from './ConditionsSummaryView';

import styles from './ConditionMetricsList.module.scss';

import {
  Card,
  LinkButton,
  ButtonColors,
  ButtonSizes,
  TextLink,
  PageContainer,
  ContainerSizes,
} from 'components';
import { TextColor, Typography } from '@repo/ui';
import useConditionMetrics from 'services/conditions/useConditionMetrics';
import useActionPlanData from 'services/actionPlan/actionPlanData';

export const ConditionMetricsList = () => {
  const dispatch = useDispatch();
  const { currentKit, currentKitId } = useCurrentKitAndTinyAccount();

  const { groupConditionMetricItems } = useConditionMetrics();
  const { data: actionPlanData } = useActionPlanData({ kitId: currentKitId });

  const [{ data: dataConditions }, { data: dataActionPlan }] =
    useSuspenseQueries({
      queries: [
        getConditionMetricsQuery(currentKitId),
        getConditionActionPlanQuery(currentKitId),
      ],
    });

  const [showModal, setShowModal] = useState(false);
  const toggleConditionsInfoModal = () => {
    setShowModal(!showModal);
  };
  const conditionsDisclaimerModal = {
    name: 'conditionsDisclaimer',
    props: { kitId: currentKitId },
  };
  const hasOpenedConditionsTable = useSelector(
    getHasModalOpened(conditionsDisclaimerModal),
  );
  const handleConditionsDisclaimerModalOpened = async () => {
    dispatch(setModalOpened(conditionsDisclaimerModal));
  };

  if (!currentKitId || !currentKit) return <></>;

  const groupedResult = useMemo(
    () =>
      dataConditions &&
      dataActionPlan &&
      groupConditionMetricItems(
        dataConditions,
        dataActionPlan,
        actionPlanData ?? [],
      ),
    [dataConditions, dataActionPlan],
  );

  const { sampling_type, age_at_sampling } = currentKit;

  let type;
  let imgType;
  if (sampling_type === KitType.VAGINAL) {
    type = 'Vaginal';
    imgType = conditionsVaginal;
  } else if (age_at_sampling! < 365) {
    type = 'Baby';
    imgType = conditionsBaby;
  } else if (age_at_sampling! < 1095) {
    type = 'Toddler';
    imgType = conditionsToddler;
  } else if (age_at_sampling! < 6570) {
    type = 'Child';
    imgType = conditionsChild;
  } else {
    type = 'Adult';
    imgType = conditionsAdult;
  }

  return (
    <PageContainer size={ContainerSizes.LG}>
      <ConditionsInfoModal
        showModal={!hasOpenedConditionsTable || showModal}
        toggle={toggleConditionsInfoModal}
        onModalOpen={handleConditionsDisclaimerModalOpened}
      />
      <div className='flex flex-column gap-4'>
        <Card>
          <div className={styles.card}>
            <div>
              <div className='flex justify-between align-items-center mb-3'>
                <Typography variant={'heading-s'} className='mb-2'>
                  Metrics related to conditions
                </Typography>
              </div>
              <div className='flex flex-column gap-3'>
                <Typography variant={'heading-l'}>
                  {`${type} conditions & the microbiome`}
                </Typography>
                <Typography variant={'heading-s'} className='dark-grey'>
                  These health conditions are associated with microbiome
                  patterns that may increase or decrease risk, but they are not
                  the only factors involved. Genetics, diet, lifestyle, and
                  other factors also contribute to overall susceptibility.
                </Typography>
              </div>
            </div>
            <img className={styles.cardImage} src={imgType} alt='conditions' />
          </div>
          <div className={styles.conditionMetricsGridListContainer}>
            {groupedResult?.map((group, index) => (
              <ConditionMetricCard
                key={group.id}
                title={group.condition_display_name}
                action_items={group.action_items.length}
                link={`${group.condition}`}
                evaluationCounts={{
                  great: group.good,
                  okay: group.okay,
                  improve: group.improve,
                  support: group.support,
                }}
              />
            ))}
          </div>
        </Card>
        <div className={styles.actionPlanButton}>
          <LinkButton
            href={`/action-plan/${currentKitId}`}
            size={ButtonSizes.M}
            color={ButtonColors.PURPLE}
            label='Go to action plan'
            width={'full'}
          />
        </div>
        <div>
          <Card>
            <div>
              <div className='flex justify-between align-items-center mb-3'>
                <Typography variant={'heading-s'}>Emerging Research</Typography>
              </div>
              <div className={styles.researchCardContent}>
                <Typography variant={'heading-s'} className='dark-grey'>
                  View the prior emerging research on biomarkers and
                  associations.
                </Typography>
                <TextLink
                  label='Read more'
                  iconNameRight='chevronForward'
                  to={`/results/${currentKitId}/conditions`}
                  color={TextColor.PURPLE}
                  className={styles.cardLink}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </PageContainer>
  );
};
