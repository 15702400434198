export enum SubscriptionType {
  KITS = 'kits',
  TINY_PLUS = 'tiny_plus',
  PRACTITIONER = 'practitioner',
}
export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  DELETED = 'deleted',
}
export enum SubscriptionInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type SubscriptionCharge = {
  id: number;
  subscription_id: number;
  status: string;
  created_at?: string;
  date_created_at?: string;
  date_processed_at?: string;
  date_scheduled_at?: string;
  date_updated_at?: string;
  discount_code?: string | null;
  error?: string;
  error_type?: string;
  note?: string;
  shopify_product_id?: number;
  shopify_variant_id?: number;
  total_price?: number;
  type?: string;
  fulfillment_order_id?: number;
  updated_at?: string;

  subscription?: Subscription;
  shopify_product?: ShopifyProduct;
};

export type Subscription = {
  cancellation_reason: null;
  created_at: string;
  customer_id: number;
  date_cancelled_at: string;
  date_created_at: string;
  date_deleted_at: string;
  date_next_charge_scheduled: string;
  date_subscription_ends: string;
  date_updated_at: string;
  email: string;
  id: number;
  mainaccount_id: string;
  order_interval_frequency: number;
  order_interval_unit: SubscriptionInterval;
  price: string;
  quantity: number;
  shopify_customer_id: number;
  shopify_product_id: number;
  shopify_product_name: string;
  shopify_variant_id: number;
  status: SubscriptionStatus;
  subscription_type: SubscriptionType;
  updated_at: string;
  kits_count_used?: number | null;
  kits_count_total?: number | null;
  consult_credits_count_used?: number | null;
  consult_credits_count_total?: number | null;
  shopify_product?: ShopifyProduct;
  next_charge?: SubscriptionCharge | null;
};

export type CustomerAddress = {
  id: number;
  customer_id: number;
  payment_method_id: number;
  address1: null | string;
  address2: null | string;
  city: string;
  company: null | string;
  country_code: string;
  created_at: string;
  discounts: [
    {
      id: number;
    },
  ];
  first_name: string;
  last_name: string;
  order_attributes: [
    {
      name: string;
      value: string;
    },
  ];
  order_note: null | string;
  phone: string;
  presentment_currency: string;
  province: string;
  shipping_lines_conserved: [
    {
      code: string;
      price: string;
      title: string;
    },
  ];
  shipping_lines_override: [];
  updated_at: string;
  zip: string;
};

export type Customer = {
  id: number;
  analytics_data: {
    utm_params: [];
  };
  created_at: string;
  email: string;
  external_customer_id: {
    ecommerce: string;
  };
  first_charge_processed_at: string;
  first_name: string;
  has_payment_method_in_dunning: false;
  has_valid_payment_method: boolean;
  hash: string;
  include: {
    addresses: CustomerAddress[];
  };
  last_name: string;
  phone: string;
  subscriptions_active_count: number;
  subscriptions_total_count: number;
  tax_exempt: boolean;
  updated_at: string;
};

export type ShopifyProduct = {
  id: number;
  variant_id?: number;
  name?: string;
  lookup_code?: string;
  num_kits_gut?: number;
  num_kits_vaginal?: number;
  num_kits_stool_chemistry?: number;
  is_research?: boolean;
  is_expedited?: boolean;
  is_program?: boolean;
  fulfill?: boolean;
  consult_credits?: number;
  type?: string;
  calendly_link?: string;
  fulfillment_interval: number;
  price?: number;
  is_practitioner_kit_order?: number;
  created_at?: string;
  updated_at?: string;
};
