import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { AxiosError } from 'axios';

import useUnregisteredKit from 'services/kits/useUnregisteredKit';
import TinyAccount from '@repo/types/TinyAccount';
import Kit from '@repo/types/Kit';

import {
  ContainerSizes,
  PageContainer,
  Spinfinity,
  Typography,
} from 'components';
import { PartnerLogo } from './PartnerLogo';
import { StepTracker } from './steps/StepTracker';
import { InformationStep } from './steps/InformationStep';
import { SurveyStep } from './steps/SurveyStep';
import { FinalStep } from './steps/FinalStep';

export type KitActivationState = {
  kit: Partial<Kit>;
  tinyAccount: Partial<TinyAccount>;
};

export type PartnerKitStepProps = {
  step: number;
  setCurrentActivationStep: (step: number) => void;
  activationState: KitActivationState;
  setActivationState: (state: KitActivationState) => void;
};

export type PartnerKitStep = {
  id: string;
  Component: React.FC<PartnerKitStepProps>;
  exactPath?: boolean;
  optional?: boolean;
};

export const registerPartnerKitSteps: readonly PartnerKitStep[] = [
  {
    id: 'informationStep',
    Component: InformationStep,
  },
  {
    id: 'surveyStep',
    Component: SurveyStep,
    exactPath: true,
  },
  {
    id: 'finalStep',
    Component: FinalStep,
  },
] as const;

const KitActivationForPartners = () => {
  const kitsMatch = useMatch<'kitId', string>('/partners/:kitId');
  const kitId = kitsMatch?.params.kitId;
  const { data: kit, error, isLoading, isSuccess } = useUnregisteredKit(kitId);

  const [currentActivationStep, setCurrentActivationStep] = useState(0);

  const [activationState, setActivationState] = useState<KitActivationState>({
    tinyAccount: { id: '' },
    kit: {},
  });

  useEffect(() => {
    const updateActivationState = () => {
      if (kit) {
        setActivationState(prevState => ({
          ...prevState,
          kit: kit,
          tinyAccount: kit.tinyaccount
            ? kit.tinyaccount
            : prevState.tinyAccount,
        }));
      }
    };

    const checkAndUpdateStep = () => {
      console.log('checking');
      if (kit?.tinyaccount && !kit?.date_survey_completed) {
        console.log('updating step!!');
        setCurrentActivationStep(1);
      }
    };
    console.log({ kit });
    updateActivationState();
    checkAndUpdateStep();
  }, [kit]);

  const StepComponent =
    registerPartnerKitSteps[currentActivationStep]?.Component;

  const errorMessage =
    error instanceof AxiosError
      ? error.response?.data?.message || error.message
      : error?.message;

  return (
    <PageContainer
      size={ContainerSizes.SM}
      className='flex flex-column gap-2 p-4'
    >
      <StepTracker
        step={currentActivationStep}
        steps={registerPartnerKitSteps}
      />
      {currentActivationStep !== 1 && (
        <div className='mt-4'>
          <PartnerLogo url={kit?.partner?.logo} />
        </div>
      )}
      {isLoading && <Spinfinity />}
      {(error ||
        (isSuccess &&
          !kit?.partner?.portal_config?.disable_kit_activation)) && (
        <div className='flex flex-column align-items-center gap-4 mt-4'>
          <Typography variant='body-s' className='red text-center'>
            {errorMessage ||
              'There is an error with your kit, please contact support at hello@tinyhealth.com'}
          </Typography>
        </div>
      )}
      {isSuccess && !!kit?.partner?.portal_config?.disable_kit_activation && (
        <div className='max-w-100'>
          {StepComponent && (
            <StepComponent
              step={currentActivationStep}
              setCurrentActivationStep={setCurrentActivationStep}
              activationState={activationState}
              setActivationState={setActivationState}
            />
          )}
        </div>
      )}
    </PageContainer>
  );
};

export default KitActivationForPartners;
