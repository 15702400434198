import { useState } from 'react';

import { Typography } from 'components';
import { SampleDateScreen } from './InformationScreens/SampleDateScreen';
import { MemberInfoScreen } from './InformationScreens/MemberInfoScreen';
import { FemaleAdultScreen } from './InformationScreens/FemaleAdultScreen';
import { EmailScreen } from './InformationScreens/EmailScreen';
import {
  KitActivationState,
  PartnerKitStepProps,
} from '../KitActivationForPartners';

type InformationView = 'sample_date' | 'female_adult' | 'member_info' | 'email';
export type InformationScreenProps = {
  activationState: KitActivationState;
  setActivationState: (state: KitActivationState) => void;
  setScreen: (screen: InformationView) => void;
  step?: number;
  setCurrentActivationStep?: (step: number) => void;
};

export const InformationStep = ({
  setActivationState,
  activationState,
  step,
  setCurrentActivationStep,
}: PartnerKitStepProps) => {
  const { kit } = activationState;
  const [screen, setScreen] = useState<InformationView>('sample_date');

  return (
    <div className='flex flex-column gap-2'>
      <div className='mt-3'>
        <Typography variant='body-m'>{`Kit ID: ${kit.id}`}</Typography>
      </div>
      {screen === 'sample_date' && (
        <SampleDateScreen
          activationState={activationState}
          setActivationState={setActivationState}
          setScreen={setScreen}
        />
      )}
      {screen === 'member_info' && (
        <MemberInfoScreen
          activationState={activationState}
          setActivationState={setActivationState}
          setScreen={setScreen}
        />
      )}
      {screen === 'female_adult' && (
        <FemaleAdultScreen
          activationState={activationState}
          setActivationState={setActivationState}
          setScreen={setScreen}
        />
      )}
      {screen === 'email' && (
        <EmailScreen
          activationState={activationState}
          setActivationState={setActivationState}
          setScreen={setScreen}
          step={step}
          setCurrentActivationStep={setCurrentActivationStep}
        />
      )}
    </div>
  );
};
