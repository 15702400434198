import { Icon, Typography } from 'components';
import { getEvaluationIconFromColor } from '../Insights/EvaluationGroupsSummary/EvaluationGroupsSummary';
import styles from './ConditionMetricCard.module.scss';

type ConditionMetricPillProps = {
  good: number;
  okay: number;
  improve: number;
  support: number;
};

const renderMetric = (count: number, color: string, styleClass: string) => {
  if (count > 0) {
    return (
      <div className='flex gap-1'>
        <Icon
          name={getEvaluationIconFromColor(color)}
          size='m'
          className={styleClass}
        />
        <Typography variant='body-s' className='my-auto'>
          {count}
        </Typography>
      </div>
    );
  }
  return null;
};

export const ConditionMetricPill = ({
  good,
  okay,
  improve,
  support,
}: ConditionMetricPillProps) => {
  const total = good + okay + support + (improve ? improve : 0);
  if (total === 0) {
    return null;
  }
  return (
    <div className={styles.conditionMetricPill}>
      {renderMetric(support, 'red', styles.red)}
      {renderMetric(improve, 'orange', styles.orange)}
      {renderMetric(okay, 'grey', styles.grey)}
      {renderMetric(good, 'green', styles.green)}
    </div>
  );
};
