import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';

import { apiName, apiBasePath } from 'assets';
import { useCurrentKitAndTinyAccount } from 'hooks/useCurrentKitAndTinyAccount';
import { ConditionActionPlan } from 'types/Conditions';

const getConditionActionPlan = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/conditions/action_plan/${kitId}`,
    {},
  );
  return response.result as ConditionActionPlan[];
};

export const getConditionActionPlanQuery = (kitId?: string) => ({
  queryKey: [apiName, `getConditionActionPlan`, kitId],
  queryFn: () => (kitId ? getConditionActionPlan(kitId) : []),
});

const useConditionsActionPlan = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();
  const conditionActionPlanQuery = useSuspenseQuery<ConditionActionPlan[]>(
    getConditionActionPlanQuery(currentKitId),
  );

  return conditionActionPlanQuery;
};

export default useConditionsActionPlan;
