import { useEffect } from 'react';
import { Controller, useForm, Validate } from 'react-hook-form';
import { differenceInCalendarDays, differenceInCalendarYears } from 'date-fns';

import { parseDate } from '@repo/utils';
import { getIsAdult } from 'views/Kits/steps/SampleDetailsStep';
import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  Select,
  THField,
  THRadioButton,
  Typography,
} from 'components';
import { InformationScreenProps } from '../InformationStep';

import styles from './InformationScreens.module.scss';

const monthOptions = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

type NewFamilyMemberFormValues = {
  firstName: string;
  lastName: string;
  sex: string;
  day: number | null;
  month: number | null;
  year: number | null;
  birthdate: string; //virtual field
};

export const MemberInfoScreen = ({
  setActivationState,
  activationState,
  setScreen,
}: InformationScreenProps) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<NewFamilyMemberFormValues>({
    mode: 'onTouched',
  });
  const { kit } = activationState;
  const CURRENT_YEAR = new Date().getFullYear();

  const firstNameFormField = register('firstName', {
    required: {
      value: true,
      message: 'This field is required',
    },
  });
  const lastNameFormField = register('lastName', {
    required: {
      value: true,
      message: 'This field is required',
    },
  });
  const sexFormField = register('sex', {
    required: true,
  });

  const day = watch('day');
  const month = watch('month');
  const year = watch('year');

  useEffect(() => {
    const calculateBirthdate = () => {
      if (day && month && year) {
        const birthdate = `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
        setValue('birthdate', birthdate, { shouldValidate: true });
      }
    };
    calculateBirthdate();
  }, [day, month, year]);

  const validateBirthdate: Validate<string, NewFamilyMemberFormValues> = (
    birthdate = '',
  ) => {
    const errorMessage = 'Invalid birthdate';
    const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(birthdate);
    if (!isValidDate) {
      return errorMessage;
    }

    const [year, month, day] = birthdate.split('-').map(Number);
    if (!year || !month || !day) return errorMessage;

    const date = new Date(year, month - 1, day);
    if (
      date >= new Date() ||
      date.getFullYear() !== year ||
      date.getMonth() + 1 !== month ||
      date.getDate() !== day
    ) {
      return errorMessage;
    }
    return true;
  };

  const submitTinyAccountRegistrationForm = async (
    formData: NewFamilyMemberFormValues,
  ) => {
    try {
      const birthdate = new Date(formData.birthdate.replace(/-/g, '/'));
      const sampleDate = parseDate(kit.sample_date);

      //difference is based on difference from current date and birthdate
      //if the number is negative then that would mean that the user input birthdate in the future
      const yearsOld = differenceInCalendarYears(new Date(), birthdate);
      const daysOld = differenceInCalendarDays(new Date(), birthdate);

      //if the number is negative then that would mean that the user input birthdate in the future
      if (yearsOld < 0 || daysOld < 0)
        throw new Error('Birthdate cannot be in the future');
      if (birthdate.toString() === 'Invalid Date' || yearsOld > 102)
        throw new Error('Must have been born after 1920');
      if (birthdate && birthdate >= sampleDate) {
        throw new Error('Sample cannot be taken before birth');
      }

      const isMale = formData.sex === 'M';
      const isAdult = getIsAdult(formData.birthdate ?? '');

      //update state and continue
      setActivationState({
        kit: {
          ...activationState.kit,
          age_at_sampling: daysOld,
        },
        tinyAccount: {
          ...activationState.tinyAccount,
          first_name: formData.firstName?.trim(),
          last_name: formData.lastName?.trim(),
          birthdate: formData.birthdate,
          sex: formData.sex,
        },
      });
      if (!isMale && isAdult) {
        setScreen('female_adult');
      } else {
        setScreen('email');
      }
    } catch (error) {
      setError('birthdate', {
        type: 'manual',
        message: error instanceof Error ? error.message : 'Invalid birthdate',
      });
    }
  };

  return (
    <div className='flex flex-column gap-2'>
      <div className='flex flex-column gap-1'>
        <Typography variant='heading-l'>Who is this sample for?</Typography>
        <Typography variant='label' className='grey-text'>
          If it is for your child, fill in their information.
        </Typography>
        <form
          onSubmit={handleSubmit(submitTinyAccountRegistrationForm)}
          className='mt-3'
        >
          <div className='items-start flex'>
            <THField
              type='text'
              label='First name'
              classes='half'
              errors={errors}
              {...firstNameFormField}
            />
            <THField
              type='text'
              label='Last name'
              classes='half'
              errors={errors}
              {...lastNameFormField}
            />
          </div>
          <div className={styles.birthdateContainer}>
            <div>
              <label htmlFor='month'>Birthday month</label>
              <Controller
                name='month'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Select
                      options={monthOptions}
                      value={value}
                      name={name}
                      placeholder='Select month'
                      enableSearch
                      onChange={onChange}
                    />
                  );
                }}
              />
              {errors.month && <span>This field is required</span>}
            </div>
            <div>
              <label htmlFor='day'>Birthday day</label>
              <Controller
                name='day'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Select
                      options={Array.from({ length: 31 }, (_, i) => ({
                        value: i + 1,
                        label: `${i + 1}`,
                      }))}
                      name={name}
                      value={value}
                      placeholder='Select day'
                      enableSearch
                      onChange={onChange}
                    />
                  );
                }}
              />
              {errors.day && <span>This field is required</span>}
            </div>
            <div>
              <label htmlFor='year'>Birthday year</label>
              <Controller
                name='year'
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Select
                      options={Array.from({ length: 100 }, (_, i) => ({
                        value: CURRENT_YEAR - i,
                        label: `${CURRENT_YEAR - i}`,
                      }))}
                      name={name}
                      value={value}
                      placeholder='Select year'
                      enableSearch
                      onChange={onChange}
                    />
                  );
                }}
              />
              {errors.year && <span>This field is required</span>}
            </div>
          </div>
          <input
            {...register('birthdate', { validate: validateBirthdate })}
            readOnly
            className='hidden'
          />
          {errors.birthdate && (
            <span className={styles.formError}>{errors.birthdate.message}</span>
          )}
          <div className='mt-4'>
            <Typography variant='heading-s'>Sex</Typography>
            <div className={styles.radioBox}>
              <THRadioButton label='Female' value='F' {...sexFormField} />
              <THRadioButton label='Male' value='M' {...sexFormField} />
              <THRadioButton label='Other' value='O' {...sexFormField} />
            </div>
            {errors.sex?.type === 'required' && (
              <div className={styles.formError}>Required. Select one</div>
            )}
          </div>
          <DesignButton
            label='Continue'
            type='submit'
            color={ButtonColors.PURPLE}
            size={ButtonSizes.M}
            loading={isSubmitting}
            width={'full'}
            className='mt-4'
          />
        </form>
      </div>
    </div>
  );
};
