import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import c from 'classnames';

import useSubscription from 'services/general/useSubscription';
import useSubscriptionDiscount from 'services/general/useSubscriptionDiscount';
import useSubscriptionCancellation from 'services/general/useSubscriptionCancellation';
import {
  Button,
  Card,
  LinkButton,
  THRadioButton,
  THTextarea,
} from 'components';
import { Icon, TextLink, Typography } from '@repo/ui';

import styles from './Subscriptions.module.scss';

type SubscriptionCancelValues = {
  reason: string;
  otherComment: string;
};

const reasonsList = [
  'Too expensive',
  'Subscribed by accident',
  'I already have more tests / sessions than I need',
  'I do not need to test anymore',
  'I’ve solved my condition / family’s condition',
  'I want a different product',
  'I didn’t find the test helpful',
  'I didn’t find the consults helpful',
  'Other reason',
];

const SubscriptionsCancellation = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const navigate = useNavigate();
  const [step, setStep] = useState('reason'); //reason, fifty, hundred
  const { data, isLoading: isLoadingSubscription } =
    useSubscription(subscriptionId);
  const subscriptionCancellationMutation = useSubscriptionCancellation();
  const subscriptionDiscountMutation = useSubscriptionDiscount();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SubscriptionCancelValues>({ mode: 'onTouched' });

  const subscriptionPrice = !data?.subscription?.price
    ? 0
    : parseFloat(data.subscription.price);
  const subscriptionKits =
    (data?.subscription?.shopify_product?.num_kits_gut ?? 0) +
    (data?.subscription?.shopify_product?.num_kits_vaginal ?? 0) +
    (data?.subscription?.shopify_product?.num_kits_stool_chemistry ?? 0);
  const subscriptionConsults =
    data?.subscription?.shopify_product?.consult_credits ?? 0;

  const cancelSubscription = async (formData: SubscriptionCancelValues) => {
    if (!subscriptionId) return;

    try {
      await subscriptionCancellationMutation.mutateAsync({
        subscriptionId,
        cancellation_reason: formData.reason,
        cancellation_reason_comments:
          formData.reason === 'Other reason' ? formData.otherComment : '',
      });
      navigate(`/account/subscriptions`);
      toast.success('Subscription updated');
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
  };

  const submitCancellation = async (formData: SubscriptionCancelValues) => {
    if (!subscriptionId) return;
    //cancel subscription if reason is 'I want a different product' or if we can't offer discount (they already used it)
    if (
      formData.reason === 'I want a different product' ||
      !data?.can_offer_discount
    ) {
      cancelSubscription(formData);
      return;
    }
    setStep('fifty');
  };

  const rejectOffers = () => {
    const formData = getValues();
    cancelSubscription(formData);
  };

  const acceptOffer = (discount: number) => async () => {
    if (!subscriptionId) return;
    try {
      await subscriptionDiscountMutation.mutateAsync({
        subscriptionId,
        discount,
      });
      navigate(`/account/subscriptions`);
      toast.success('Subscription updated');
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
  };

  if (step === 'reason') {
    return (
      <div className={styles.accountContainer}>
        <div>
          <TextLink
            to='/account/subscriptions'
            label='Back to Account'
            iconNameLeft='chevronBack'
            size='body-s'
          />
        </div>
        <Card variant='wrapper' className={c(styles.cardPadding, 'mt-4')}>
          <Typography variant='heading-l' className={styles.title}>
            We’re sad to see you go! Why do you wish to cancel?
          </Typography>
          <form onSubmit={handleSubmit(submitCancellation)}>
            <div className={c(styles.radioBox, 'pt-2')}>
              {reasonsList.map((reason: string) => (
                <THRadioButton
                  key={reason}
                  label={reason}
                  value={reason}
                  {...register('reason', {
                    required: true,
                  })}
                />
              ))}
              {watch('reason') === 'Other reason' && (
                <THTextarea
                  rows={3}
                  placeholder='Add reasons or feedback'
                  maxLength={240}
                  errors={errors}
                  {...register('otherComment', {
                    required: {
                      value: true,
                      message: 'Required.',
                    },
                  })}
                />
              )}
            </div>

            <div className={styles.buttonGroup}>
              <LinkButton width='full' href={`/account`}>
                Keep subscription
              </LinkButton>
              <Button
                type='submit'
                color='red'
                variant='outline'
                width='full'
                loading={isLoadingSubscription || isSubmitting}
                disabled={!isValid}
              >
                Cancel subscription
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
  if (step === 'fifty') {
    return (
      <div className={styles.accountContainer}>
        <div>
          <TextLink
            to='/account/subscriptions'
            label='Back to Account'
            iconNameLeft='chevronBack'
            size='body-s'
          />
        </div>
        <Card className={c(styles.cardPadding, 'mt-4')}>
          <Typography variant='heading-s' className='grey-text mb-4'>
            An exclusive discount for you
          </Typography>
          <Typography variant='heading-l' className={styles.title}>
            Get <span className='green'>$50</span> off your membership fee
          </Typography>
          <div className={styles.offerContainer}>
            <div className={styles.iconWrapper}>
              <Icon name='saleFilled' className='green' />
            </div>
            <div className='flex flex-column'>
              <div className='ml-3'>
                <Typography variant='heading-m' className={styles.title}>
                  {`Now $${subscriptionPrice - 50} `}
                </Typography>
                <Typography variant='heading-s'>
                  <span
                    className={styles.strike}
                  >{`$${subscriptionPrice}`}</span>{' '}
                  / year
                </Typography>
              </div>
              <ul className='flex flex-column gap-2 mt-2'>
                <li>
                  <Typography variant='body-s'>{`Grants you ${subscriptionKits} kit${subscriptionKits > 1 ? 's' : ''} ${subscriptionConsults ? `and ${subscriptionConsults} call${subscriptionConsults > 1 ? 's' : ''} ` : ''}for the year`}</Typography>
                </li>
                <li>
                  <Typography variant='body-s'>
                    Member-only rates for additional kits at $169 / kit (
                    <span className='green'>Save $30</span>)
                  </Typography>
                </li>
                <li>
                  <Typography variant='body-s'>
                    Member-only rates for additional sessions at $39 / call (
                    <span className='green'>Save $61</span>)
                  </Typography>
                </li>
                <li>
                  <Typography variant='body-s'>
                    Member-only content and virtual events
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
          <form onSubmit={handleSubmit(acceptOffer(50))}>
            <div className={styles.buttonGroup}>
              <Button type='submit' color='green' width='full'>
                Accept offer
              </Button>
              <Button
                onClick={() => setStep('hundred')}
                color='red'
                variant='outline'
                width='full'
                loading={isSubmitting}
                disabled={!isValid}
              >
                No thanks, cancel
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
  if (step === 'hundred') {
    return (
      <div className={styles.accountContainer}>
        <div>
          <TextLink
            to='/account/subscriptions'
            label='Back to Account'
            iconNameLeft='chevronBack'
            size='body-s'
          />
        </div>
        <Card className={c(styles.cardPadding, 'mt-4')}>
          <Typography variant='heading-s' className='grey-text mb-4'>
            This Week Only
          </Typography>
          <Typography variant='heading-l' className={styles.title}>
            We’d love to make testing work for you. Here’s our limited-time best
            value:
          </Typography>
          <div className={styles.bestValueContainer}>
            <div className={styles.bestValue}>
              <Typography variant='label' weight='bold'>
                Best Value
              </Typography>
            </div>
            <Typography variant='heading-l'>
              <span className='green'>$100 off</span> your membership
            </Typography>
            <div>
              <Typography variant='heading-m' className={styles.title}>
                {`Only $${subscriptionPrice - 100} `}
                <span
                  className={styles.strike}
                >{`$${subscriptionPrice}`}</span>{' '}
                / year
              </Typography>
            </div>
          </div>
          <form onSubmit={handleSubmit(acceptOffer(100))}>
            <div className={styles.buttonGroup}>
              <Button type='submit' color='green' width='full'>
                Accept now
              </Button>
              <Button
                onClick={rejectOffers}
                color='red'
                variant='outline'
                width='full'
                loading={isSubmitting}
                disabled={!isValid}
              >
                Decline $100
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
};

export default SubscriptionsCancellation;
