import c from 'classnames';

import { PartnerKitStep } from '../KitActivationForPartners';

import styles from './StepTracker.module.scss';

export const StepTracker = ({
  step,
  steps,
}: {
  step: number;
  steps: readonly PartnerKitStep[];
}) => {
  return (
    <div className={styles.stepTracker}>
      <div className={styles.stepsProgress}>
        {steps.map((s, idx) => (
          <div
            className={c(
              styles.progressMarker,
              idx === step && styles.partial,
              idx < step && styles.filled,
              idx === step && idx === steps.length - 1 && styles.filled,
            )}
            key={idx}
          ></div>
        ))}
      </div>
    </div>
  );
};
