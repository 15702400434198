import { useSuspenseQuery } from '@tanstack/react-query';
import { API } from 'api';
import { apiName, apiBasePath } from 'assets/constants';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { useMemo } from 'react';
import { ActionPlanItem } from 'types/ActionPlan';
import { ConditionActionPlan, ConditionMetric } from 'types/Conditions';

type ConditionMetricGroupings = {
  id: number;
  condition: string;
  condition_display_name: string;
  good: number;
  okay: number;
  improve: number;
  support: number;
  metrics: {
    metric_id: number;
    name: string;
    evaluation_color: string;
    user_value: number;
    health_metrics_barscale_percentage: number;
    data_type: string;
  }[];
  action_items: ConditionActionPlan[];
};

export const getMetricConditions = async () => {
  const response = await API.get(apiName, `${apiBasePath}/conditions`, {});
  return response.result;
};

const getConditionActionPlan = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/conditions/action_plan/${kitId}`,
    {},
  );
  return response.result as ConditionActionPlan[];
};

const getConditionMetrics = async (kitId: string) => {
  const response = await API.get(
    apiName,
    `${apiBasePath}/conditions/metrics/${kitId}`,
    {},
  );
  return response.result as ConditionMetric[];
};

export const getConditionActionPlanQuery = (kitId?: string) => ({
  queryKey: [apiName, `getConditionActionPlan`, kitId],
  queryFn: () => (kitId ? getConditionActionPlan(kitId) : []),
});

export const getConditionMetricsQuery = (kitId?: string) => ({
  queryKey: [apiName, `getConditionMetrics`, kitId],
  queryFn: () => (kitId ? getConditionMetrics(kitId) : []),
});

export const getMetricConditionsQuery = () => ({
  queryKey: [apiName, 'getMetricConditions'],
  queryFn: () => getMetricConditions(),
});

const useConditionMetrics = () => {
  const { currentKitId } = useCurrentKitAndTinyAccount();

  const conditionMetricsQuery = useSuspenseQuery<ConditionMetric[]>(
    getConditionMetricsQuery(currentKitId),
  );

  const conditionActionPlanQuery = useSuspenseQuery<ConditionActionPlan[]>(
    getConditionActionPlanQuery(currentKitId),
  );

  const metricConditionsQuery = useSuspenseQuery(getMetricConditionsQuery());

  const groupConditionMetricItems = (
    metrics: ConditionMetric[],
    action_plan_items: ConditionActionPlan[],
    action_plan_recommendations: ActionPlanItem[],
  ) => {
    return metrics.reduce(
      (initialObj: ConditionMetricGroupings[], data: ConditionMetric) => {
        let condition = initialObj.find(item => item.id === data.condition_id);

        if (!condition) {
          condition = {
            id: data.condition_id,
            condition: data.condition,
            condition_display_name: data.condition_display_name,
            good: 0,
            okay: 0,
            improve: 0,
            support: 0,
            metrics: [],
            action_items: [],
          };
          initialObj.push(condition);
        }

        if (data.evaluation_color === 'green') {
          condition.good += 1;
        } else if (data.evaluation_color === 'grey') {
          condition.okay += 1;
        } else if (data.evaluation_color === 'orange') {
          condition.improve += 1;
        } else if (data.evaluation_color === 'red') {
          condition.support += 1;
        }

        condition.action_items = action_plan_items.filter(item => {
          const matched = action_plan_recommendations.find(
            recommendation =>
              recommendation.id === +item.action_plan_recommendations_id,
          );
          // NOTE If there is no match it means that the recommendation has published === 0 in the db
          if (!matched) {
            return false;
          }
          return data.condition.includes(item.tags);
        });

        condition.metrics.push({
          metric_id: data.metric_id,
          name: data.name,
          evaluation_color: data.evaluation_color,
          user_value: data.user_value,
          health_metrics_barscale_percentage:
            data.health_metrics_barscale_percentage,
          data_type: data.data_type,
        });

        return initialObj;
      },
      [],
    );
  };

  return {
    conditionMetricsQuery,
    conditionActionPlanQuery,
    metricConditionsQuery,
    groupConditionMetricItems,
  };
};

export default useConditionMetrics;
