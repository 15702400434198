import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { AxiosError } from 'axios';
import c from 'classnames';

import { Typography, Spinfinity } from 'components';
import { KitStepProps } from 'views/Kits/ActivateKit';
import KitIllustrationSvg from 'assets/images/KitIdIllustration.svg?react';
import useUnregisteredKit from 'services/kits/useUnregisteredKit';

import styles from '../Kits.module.scss';

const KitActivationStep = ({
  activationState,
  setActivationState,
  setCurrentActivationStep,
  step,
}: KitStepProps) => {
  const kitsMatch = useMatch<'kitId', string>('/kits/activate/:kitId');
  const kitId = kitsMatch?.params.kitId;

  const { data: kit, error, isLoading } = useUnregisteredKit(kitId);

  useEffect(() => {
    if (kit) {
      setActivationState({
        ...activationState,
        kit: {
          ...activationState.kit,
          id: kit.id,
          sampling_type: kit.sampling_type,
          practitioner: kit.practitioner,
        },
      });
      setCurrentActivationStep(step + 1);
    }
  }, [kit]);

  const errorMessage =
    error instanceof AxiosError
      ? error.response?.data?.message || error.message
      : error?.message;

  return (
    <div className={c(styles.stepContainer)}>
      {isLoading ? (
        <div data-testid='kit-activation-step-loading'>
          <Spinfinity />
        </div>
      ) : error ? (
        <div className='flex flex-column align-items-center gap-4 mt-4'>
          <KitIllustrationSvg className={styles.illustration} />
          <Typography variant='body-s' className='red text-center'>
            {errorMessage ||
              'There is an error with your kit, please contact support at hello@tinyhealth.com'}
          </Typography>
        </div>
      ) : null}
    </div>
  );
};

export default KitActivationStep;
