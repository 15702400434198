import { useForm } from 'react-hook-form';
import { differenceInCalendarDays, differenceInCalendarMonths } from 'date-fns';
import c from 'classnames';

import { parseDate } from '@repo/utils';
import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  Typography,
} from 'components';
import { InformationScreenProps } from '../InformationStep';

import styles from './InformationScreens.module.scss';

type SampleDateFormValues = {
  sampleDate: string;
};

export const SampleDateScreen = ({
  setActivationState,
  activationState,
  setScreen,
}: InformationScreenProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
    clearErrors,
  } = useForm<SampleDateFormValues>({
    mode: 'onTouched',
  });
  const { kit } = activationState;

  const submitSampleDate = async (formData: SampleDateFormValues) => {
    clearErrors();
    const sampleDate = parseDate(formData.sampleDate);
    const sampleDateMonths = differenceInCalendarMonths(new Date(), sampleDate);
    const sampleDateDays = differenceInCalendarDays(new Date(), sampleDate);

    if (sampleDateDays < 0) {
      setError('sampleDate', {
        type: 'timing',
        message: 'Sample cannot be taken in the future',
      });
      return;
    }
    if (sampleDateMonths > 3) {
      setError('sampleDate', {
        type: 'timing',
        message: 'Sample must be taken within the last 3 months',
      });
      return;
    }
    setActivationState({
      ...activationState,
      kit: {
        ...activationState.kit,
        sample_date: formData.sampleDate,
      },
    });
    setScreen('member_info');
  };

  return (
    <form onSubmit={handleSubmit(submitSampleDate)}>
      <div className='field mt-3'>
        <label htmlFor='sampleDate'>
          <Typography variant='heading-l'>
            When was this sample taken?
          </Typography>
        </label>
        <div className='mt-2'>
          <input
            id='sampleDate'
            className={c(
              styles.inputField,
              errors.sampleDate && styles.inputError,
            )}
            defaultValue={kit.sample_date}
            placeholder='SampleDate'
            type='date'
            {...register('sampleDate', { required: 'Please enter a date' })}
          />
          {errors.sampleDate && (
            <div
              data-testid='form-error'
              className={c(styles.formError, 'ps-2')}
            >
              {errors.sampleDate.message}
            </div>
          )}
        </div>
      </div>
      <DesignButton
        label='Continue'
        type='submit'
        loading={isSubmitting}
        disabled={!isValid}
        size={ButtonSizes.M}
        color={ButtonColors.PURPLE}
        width={'full'}
        className='mt-4'
      />
    </form>
  );
};
