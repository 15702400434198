import c from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Card, Icon, TextLink, Typography } from 'components';
import useCurrentKitAndTinyAccount from 'hooks/useCurrentKitAndTinyAccount';
import { ConditionActionPlan, NewCondition } from 'types/Conditions';

import styles from './ConditionMetricActions.module.scss';

type ConditionActionPlanWithDisplayTitle = ConditionActionPlan & {
  display_title: string;
};

type ConditionCardProps = {
  condition: NewCondition;
  actions: ConditionActionPlanWithDisplayTitle[];
  className?: string;
};

export const ConditionMetricActions = ({
  condition,
  actions,
  className,
}: ConditionCardProps) => {
  const { currentKitId } = useCurrentKitAndTinyAccount();

  const navigate = useNavigate();

  const navigateToAction = (action: ConditionActionPlan) => {
    navigate(
      `/action-plan/${currentKitId}/action/${action.action_plan_recommendations_id}`,
    );
  };

  return (
    <Card
      variant='wrapper'
      className={c(styles.card, 'purple-border-left-slim', className)}
    >
      <Typography variant='heading-m' className={c(styles.cardTitle, 'mb-2')}>
        Actions for {condition.condition_display_name}
      </Typography>
      <div className={styles.cardContentContainer}>
        {!actions.length ? (
          <Typography variant='body-s'>
            No actions related to this condition
          </Typography>
        ) : (
          actions.map((action, index) => {
            if (!action) return null;
            return (
              <div
                key={index}
                className={styles.cardItem}
                onClick={() => {
                  navigateToAction(action);
                }}
              >
                <Icon
                  name='pill'
                  size='m'
                  className={c(styles.cardIcon, 'mr-2')}
                />
                <Typography variant='heading-s'>
                  {action.display_title}
                </Typography>
                <TextLink
                  label={''}
                  iconNameRight='chevronForward'
                  className={c(styles.cardIconForward, 'my-auto ml-auto gap-0')}
                  contentOnly
                />
              </div>
            );
          })
        )}
      </div>
    </Card>
  );
};
