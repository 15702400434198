import c from 'classnames';

import type { Association } from '@repo/types/SubMetricEvaluation';
import { Icon } from '@repo/ui';

import styles from './AssociationsList.module.scss';

const hasPositiveImpact = (association: Association) => {
  if (association.evaluation === 'good') {
    return association.direction === 'positive';
  }
  if (association.evaluation === 'bad') {
    return association.direction === 'negative';
  }
};

export const AssociationsList = ({
  associations,
}: {
  associations: Association[];
}) => {
  if (!associations?.length) return <></>;

  return (
    <div className={styles.associations}>
      {associations.map(association => (
        <div
          key={association.id}
          className={c(
            styles.associationPill,
            styles.wordBreak,
            hasPositiveImpact(association)
              ? styles.pillPositive
              : styles.pillNegative,
          )}
        >
          {!!association?.direction && (
            <div className={styles.iconWrapper}>
              <Icon
                name={
                  association?.direction === 'positive'
                    ? 'upSmall'
                    : 'downSmall'
                }
                size='s'
              />
            </div>
          )}
          <span>{association.display_name}</span>
        </div>
      ))}
    </div>
  );
};
