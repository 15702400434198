import { Controller, useForm } from 'react-hook-form';
import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  format,
} from 'date-fns';
import c from 'classnames';

import { parseDate } from '@repo/utils';
import {
  ButtonColors,
  ButtonSizes,
  DesignButton,
  Select,
  Typography,
} from 'components';
import { InformationScreenProps } from '../InformationStep';

import styles from './InformationScreens.module.scss';

type SampleDetailsFormValues = {
  sampleDate: string;
  babyBirthdate?: string;
  dueDate?: string;
  samplingDetail?: number;
};
const samplingDetailOptions = [
  { label: 'Pregnant', value: 1 },
  { label: 'Previously given birth', value: 2 },
  {
    label: 'Trying to conceive (currently or starting to try soon)',
    value: 3,
  },
  { label: 'None of the above', value: 4 },
];

export const FemaleAdultScreen = ({
  setActivationState,
  activationState,
  setScreen,
}: InformationScreenProps) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<SampleDetailsFormValues>({
    mode: 'onChange',
  });
  const { kit, tinyAccount } = activationState;
  const samplingDetailAnswer = watch('samplingDetail');

  const submitAdultFemaleForm = async (formData: SampleDetailsFormValues) => {
    const birthdate = parseDate(tinyAccount?.birthdate);
    const sampleDate = parseDate(kit.sample_date);

    //difference is based on difference from current date and birthdate
    //if the number is negative then that would mean that the user input birthdate in the future
    const yearsOld = differenceInCalendarYears(new Date(), birthdate);
    const daysOld = differenceInCalendarDays(new Date(), birthdate);

    //if the number is negative then that would mean that the user input birthdate in the future
    if (yearsOld < 0 || daysOld < 0)
      throw new Error('Birthdate cannot be in the future');
    if (birthdate.toString() === 'Invalid Date' || yearsOld > 102)
      throw new Error('Must have been born after 1920');
    if (birthdate && birthdate > sampleDate) {
      throw new Error('Sample cannot be taken before birth');
    }

    const babyBirthdate = formData.babyBirthdate
      ? parseDate(formData.babyBirthdate)
      : null;
    const dueDate = formData.dueDate ? parseDate(formData.dueDate) : null;

    let weekOfPregnancy = null;
    if (samplingDetailAnswer === 1 && dueDate) {
      const dueDateWeeks = differenceInCalendarWeeks(dueDate, sampleDate);
      weekOfPregnancy = 40 - dueDateWeeks;
    }

    let daysPP = null;
    if (babyBirthdate) {
      daysPP = differenceInCalendarDays(sampleDate, babyBirthdate);
    }

    //update state and continue
    setActivationState({
      kit: {
        ...activationState.kit,
        ttc: samplingDetailAnswer === 3,
        pregnant: samplingDetailAnswer === 1,
        week_of_pregnancy: weekOfPregnancy,
        post_partum: !!formData.babyBirthdate,
        days_pp: daysPP,
      },
      tinyAccount: {
        ...activationState.tinyAccount,
        expected_due_date:
          samplingDetailAnswer === 1 ? formData.dueDate : undefined,
        delivery_date:
          samplingDetailAnswer === 2 ? formData.babyBirthdate : undefined,
        ttc_date:
          samplingDetailAnswer === 3
            ? format(new Date(), 'yyyy-MM-dd')
            : undefined,
      },
    });

    setScreen('email');
  };

  return (
    <div className='flex flex-column gap-2'>
      <div className='flex flex-column gap-1'>
        <Typography variant='heading-l'>
          At the time of sampling, did any of these apply?
        </Typography>
        <form onSubmit={handleSubmit(submitAdultFemaleForm)} className='mt-3'>
          <div className='field mt-3'>
            <Controller
              name='samplingDetail'
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  name={name}
                  options={samplingDetailOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.samplingDetail?.type === 'required' && (
              <div data-testid='form-error' className={styles.formError}>
                Please choose an option
              </div>
            )}{' '}
            <p className='mb-0 f7'>
              We use your answer to include insights related to conception and
              pregnancy in your report.
            </p>
          </div>
          {samplingDetailAnswer === 1 && (
            <div className='field mt-3'>
              <label htmlFor='dueDate'>When is the due date?</label>
              <input
                id='dueDate'
                className={c(
                  styles.inputField,
                  errors.dueDate && styles.inputError,
                )}
                placeholder='Due Date'
                type='date'
                {...register('dueDate', {
                  required: samplingDetailAnswer === 1,
                  shouldUnregister: true,
                  validate: value => {
                    if (!value) return true;
                    const selectedDate = parseDate(value);
                    const today = new Date();
                    return (
                      selectedDate > today || 'Due date cannot be in the past'
                    );
                  },
                })}
              />
              {errors.dueDate?.type === 'required' && (
                <div data-testid='form-error' className={styles.formError}>
                  Due date is required
                </div>
              )}
              {errors.dueDate?.type === 'validate' && (
                <div data-testid='form-error' className={styles.formError}>
                  {errors.dueDate.message}
                </div>
              )}
            </div>
          )}
          {samplingDetailAnswer === 2 && (
            <div className='field mt-3'>
              <label htmlFor='babyBirthdate'>When was the baby born?</label>
              <input
                id='babyBirthdate'
                className={c(
                  styles.inputField,
                  errors.babyBirthdate && styles.inputError,
                )}
                placeholder='Baby birth date'
                type='date'
                {...register('babyBirthdate', {
                  required: samplingDetailAnswer === 2,
                  shouldUnregister: true,
                })}
              />
              {errors.babyBirthdate?.type === 'required' && (
                <div data-testid='form-error' className={styles.formError}>
                  Please enter a date
                </div>
              )}
            </div>
          )}
          <DesignButton
            label='Continue'
            type='submit'
            color={ButtonColors.PURPLE}
            size={ButtonSizes.M}
            disabled={!isValid}
            loading={isSubmitting}
            width={'full'}
            className='mt-4'
          />
        </form>
      </div>
    </div>
  );
};
