import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';

import { getPractitionerId } from 'store/account';
import {
  ButtonColors,
  ButtonSizes,
  ButtonVariants,
  DesignButton,
  Spinfinity,
} from 'components';
import { KitStepProps } from 'views/Kits/ActivateKit';

import styles from '../Kits.module.scss';

const PractitionerStep = ({
  setActivationState,
  activationState,
  setCurrentActivationStep,
  step,
}: KitStepProps) => {
  const { kit, tinyAccount } = activationState;

  //pre-select the current practitioner, or the practitioner which discount code was used to buy the kit or none
  const currentPractitioner = useSelector(getPractitionerId);
  const practitionerId = useMemo(
    () => (currentPractitioner ? currentPractitioner : kit?.practitioner?.id),
    [currentPractitioner, kit],
  );

  useEffect(() => {
    if (!!kit && !kit.practitioner) {
      setCurrentActivationStep(step + 1);
    }
  }, [kit, setCurrentActivationStep, step]);

  const moveToNextScreen = async () => {
    setCurrentActivationStep(step + 1);
  };

  if (!kit?.practitioner) return <Spinfinity />;
  return (
    <div className={c(styles.stepContainer)}>
      <h2>{`${tinyAccount?.first_name}’s practitioner will have access to their results. Practitioner:`}</h2>

      <p className='green'>
        {`${kit.practitioner.user?.first_name} ${kit.practitioner.user?.last_name}`}
      </p>

      <p>
        They will be notified of your results with up to 3 days to view them
        before you.
      </p>
      <p>
        If you would like to change or remove your practitioner, go to the
        Account page.
      </p>
      <DesignButton
        label='I understand'
        type='button'
        size={ButtonSizes.M}
        variant={ButtonVariants.SOLID}
        color={ButtonColors.GREEN}
        width={'full'}
        className='mt-4 mx-auto'
        onClick={() => moveToNextScreen()}
      />
    </div>
  );
};

export default PractitionerStep;
