import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import c from 'classnames';

import useTinyAccounts from 'services/account/useTinyAccounts';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { getHomePageTinyAccountId } from 'store/home';
import TinyAccount from 'types/TinyAccount';

import CTASection from 'views/Home/HomeContent/CTASection/CTASection';
import {
  ContainerSizes,
  PageContainer,
  TinyAccountSelector,
  Typography,
} from 'components';
import { KitsSection } from './KitsSection/KitsSection';
import { NewFamilyMemberCard } from '../NewFamilyMemberCard/NewFamilyMemberCard';
import { RecommendedForYou } from './RecommendedForYou/RecommendedForYou';
import { ProcessingYourSample } from './ProcessingYourSample/ProcessingYourSample';
import { ConsultNoteReminder } from './ConsultNoteReminder/ConsultNoteReminder';
import { MembershipSection } from './MembershipSection/MembershipSection';
import { ConsultReminder } from './ConsultReminder/ConsultReminder';
import { HomeNextSteps } from './HomeNextSteps/HomeNextSteps';
import { HomeActions } from './HomeActions/HomeActions';

import styles from './HomeContent.module.scss';

const WelcomeSection = ({
  tinyAccount,
}: {
  tinyAccount?: TinyAccount | null;
}) => {
  if (!tinyAccount) return <></>;
  return (
    <div className={styles.welcomeSection}>
      <div className='flex flex-column gap-2'>
        <Typography variant='heading-2xl'>
          Welcome, {tinyAccount?.first_name}
        </Typography>
        <Typography variant='heading-l'>
          Congrats on prioritizing your wellness!
        </Typography>
      </div>
      <div className='flex flex-column gap-2'>
        <Typography variant='body-s' serif>
          {`Everyone’s journey is unique, and we’re here to support you each step
          of the way. You can manage everything right here —from your test
          results to your Action Plan to booking a session with your microbiome
          specialist. Feel free to reach out if you have any questions, `}
          <span className={styles.underline}>hello@tinyhealth.com</span>.
        </Typography>
      </div>
    </div>
  );
};

export const HomeContent = () => {
  const navigate = useNavigate();
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const { tinyAccount, kits, latestKit } = useTinyAccountForHome(tinyAccountId);
  const homePageTinyAccountId = useSelector(getHomePageTinyAccountId);
  const { tinyAccounts } = useTinyAccounts();

  const showWelcomeSection = useMemo(() => {
    return !kits?.length;
  }, [kits]);

  const tinyAccountSelected = useCallback(
    async (tinyAccount?: Partial<TinyAccount>) => {
      if (tinyAccount?.id) {
        navigate(`/`, { state: { tinyAccountId: tinyAccount.id } });
      }
    },
    [navigate],
  );

  return (
    <PageContainer size={ContainerSizes.LG} className={styles.pageContainer}>
      <div
        className={c(
          styles.mainSectionContainer,
          showWelcomeSection && styles.withWelcomeSection,
        )}
      >
        <div className={styles.wideSection}>
          {showWelcomeSection ? (
            <WelcomeSection tinyAccount={tinyAccount} />
          ) : (
            <div className={styles.contentContainer}>
              <ConsultReminder className='hide-on-mobile' />
              <ConsultNoteReminder className='hide-on-mobile' />
              <ProcessingYourSample />
              <CTASection tinyAccountId={tinyAccountId} />
              <KitsSection tinyAccountId={tinyAccountId} />
              <NewFamilyMemberCard />
              <HomeActions kit={latestKit} />
              <MembershipSection tinyAccountId={tinyAccountId} />
            </div>
          )}
        </div>
        <div className={styles.slimSection}>
          {!!tinyAccounts?.length && tinyAccounts.length > 1 && (
            <div className={styles.forceSelectorStyles}>
              <TinyAccountSelector
                onSelection={tinyAccountSelected}
                currentTinyAccountId={tinyAccountId ?? homePageTinyAccountId}
                withProfile
              />
            </div>
          )}
          <div className={styles.stickyContent}>
            <div className='pt-2'>
              <div className='flex flex-column gap-1'>
                <ConsultReminder className='hide-on-desktop' />
                <ConsultNoteReminder className='hide-on-desktop' />
              </div>
              <HomeNextSteps />
            </div>
          </div>
        </div>
      </div>
      <RecommendedForYou tinyAccountId={tinyAccountId} />
    </PageContainer>
  );
};
