import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';

import useConsults from 'services/general/consultsList';
import { useTinyAccountForHome } from 'hooks/useTinyAccountForHome';
import { getHomePageTinyAccountId } from 'store/home';

import { Card } from 'components';
import { Icon, Typography } from '@repo/ui';

import styles from './ConsultNoteReminder.module.scss';

export const ConsultNoteReminder = ({
  className = '',
}: {
  className: string;
}) => {
  const tinyAccountId = useSelector(getHomePageTinyAccountId);
  const { kits } = useTinyAccountForHome(tinyAccountId);
  const { data: consultsList } = useConsults();

  const latestConsultWithNotePublished = useMemo(() => {
    if (!kits || !consultsList) return null;

    for (const kit of kits) {
      const consultWithNote = consultsList.find(
        consult =>
          consult.status.includes('completed') &&
          consult.kit_id === kit.id &&
          consult.consult_note?.status === 'published',
      );
      // if we find one we stop searching
      if (consultWithNote) {
        return consultWithNote;
      }
    }

    return null;
  }, [kits, consultsList]);

  const agentName = latestConsultWithNotePublished?.consult_agent?.first_name
    ? `${latestConsultWithNotePublished.consult_agent.first_name} ${latestConsultWithNotePublished.consult_agent.last_name}`
    : 'our team';

  const noteMarkdown =
    latestConsultWithNotePublished?.consult_note?.edited_value ||
    latestConsultWithNotePublished?.consult_note?.original_value;

  if (!consultsList || !latestConsultWithNotePublished || !noteMarkdown)
    return <></>;
  return (
    <Card
      variant='wrapper'
      clickable={{
        text: `Read specialist’s notes for kit ${latestConsultWithNotePublished.kit_id}`,
        href: `/consults/${latestConsultWithNotePublished.id}`,
        variety: 'Card',
        isExternal: false,
      }}
      className={c(styles.card, 'blue-border-left', className)}
    >
      <div className='flex align-item-center justify-between gap-1'>
        <div className='flex flex-column gap-2'>
          <div className='flex align-items-center gap-1 blue-icon'>
            <Icon name='messageHearthSquare' size='m' />
            <Typography variant='heading-m'>
              {`Read specialist’s notes for kit ${latestConsultWithNotePublished.kit_id}`}
            </Typography>
          </div>
          <Typography variant='label'>{`from ${agentName}`}</Typography>
        </div>
        <Icon name='chevronForward' size='l' />
      </div>
    </Card>
  );
};
