import { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import c from 'classnames';

import useSubscriptionCancellation from 'services/general/useSubscriptionCancellation';
import { Button, LinkButton, THRadioButton, THTextarea } from 'components';
import { Icon, Typography } from '@repo/ui';
import styles from './SubscriptionDetail.module.scss';
import { BackButton } from 'components/BackButton/BackButton';

type SubscriptionCancelValues = {
  reason: string;
  otherComment: string;
};

const reasonsList = [
  'Too expensive',
  'Subscribed by accident',
  'I already have more than I need',
  'I need my kits sooner',
  'I no longer use the product',
  'I want a different product',
  'I didn’t find the test helpful',
  'Other',
];
//TODO: clean up with useFeature('subscriptions_v2')
const SubscriptionCancellation = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const navigate = useNavigate();
  const [step, setStep] = useState('info'); //info or submit
  const subscriptionCancellationMutation = useSubscriptionCancellation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SubscriptionCancelValues>({ mode: 'onTouched' });

  const submitCancellation = async (formData: SubscriptionCancelValues) => {
    if (!subscriptionId) return;
    try {
      await subscriptionCancellationMutation.mutateAsync({
        subscriptionId,
        cancellation_reason: formData.reason,
        cancellation_reason_comments:
          formData.reason === 'Other' ? formData.otherComment : '',
      });
      navigate(`/account/subscription/${subscriptionId}/cancelled`);
    } catch (error) {
      toast.error('Your request could not be completed. Please try again');
    }
  };

  return step === 'info' ? (
    <div className={styles.accountContainer}>
      <div>
        <NavLink to='/account'>
          <BackButton variant='link' />
        </NavLink>
      </div>
      <Typography variant='heading-xl' className={styles.title}>
        Continue with cancelling your subscription?
      </Typography>
      <div className={c(styles.secondary, 'mt-1')}>
        You can still access the results platform. Resubscribe anytime.
      </div>
      <div className={c(styles.subtitle, 'mt-4 mb-4')}>
        When you downgrade, you will lose access to these benefits:
      </div>
      <div className={c(styles.secondary, styles.flexRow)}>
        <Icon name='bankNote' size='m' />
        $30 OFF each kit
      </div>
      <div className={c(styles.secondary, styles.flexRow)}>
        <Icon name='checkDone' size='m' />
        Kits delivered at optimal intervals
      </div>
      <div className={c(styles.secondary, styles.flexRow)}>
        <Icon name='lineChartUp02' size='m' />
        Easily track your progress
      </div>
      <div className={styles.buttonGroup}>
        <Button
          width='full'
          type='button'
          color='red'
          onClick={() => setStep('submit')}
        >
          Continue
        </Button>
        <LinkButton width='full' href={`/account`} variant='transparent'>
          Cancel
        </LinkButton>
      </div>
    </div>
  ) : (
    <div className={styles.accountContainer}>
      <div>
        <a onClick={() => setStep('info')}>
          <BackButton variant='link' />
        </a>
      </div>
      <Typography variant='heading-xl' className={styles.title}>
        Why do you wish to cancel?
      </Typography>
      <form onSubmit={handleSubmit(submitCancellation)}>
        <div className={c(styles.radioBox, 'pt-2')}>
          {reasonsList.map((reason: string) => (
            <THRadioButton
              key={reason}
              label={reason}
              value={reason}
              {...register('reason', {
                required: true,
              })}
            />
          ))}
          {watch('reason') === 'Other' && (
            <THTextarea
              rows={3}
              placeholder='Add reasons or feedback'
              maxLength={240}
              errors={errors}
              {...register('otherComment', {
                required: {
                  value: true,
                  message: 'Required.',
                },
              })}
            />
          )}
        </div>

        <div className={styles.buttonGroup}>
          <Button
            type='submit'
            color='red'
            loading={isSubmitting}
            disabled={!isValid}
          >
            Finish cancellation
          </Button>
          <LinkButton width='full' href={`/account`} variant='transparent'>
            Cancel
          </LinkButton>
        </div>
      </form>
    </div>
  );
};

export default SubscriptionCancellation;
